.screen-overlay {
    position:absolute;
    top: 0;
    bottom: 0;
    right:0;
    left:0;
    z-index: 1031;
    background-color: rgba(0,0,0,0.5);
    opacity: 0;
    transition: all 0.15s linear;
    pointer-events:none;
    &.active {
        opacity: 1;
        display: block;
        pointer-events: all;
    }
}