.listingLocationLinks {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;

	&__container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		width: 100%;
		margin: 0 1.5rem 1rem 1.5rem;
	}

	&__title {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;
		
		width: 100%;
	}

	&__description {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;

		width: 100%;
	}

	&__lists {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		flex-wrap: nowrap;
		
		width: 100%;

		&__list {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: nowrap;
			width: 100%;
			// margin: 0 1.5rem 1rem 1.5rem;

			&:not(:first-of-type) {
				margin: 0 1.5rem 1rem 1.5rem;
			}

			& h4 {
				border-bottom: 2px solid #f37ed5;
				padding-bottom: 1rem;
			}
		}

		& ul {

			list-style-type: none;
			padding: 0.1rem;
			border-right: 2px solid #e9ebee;
			width: 100%;

			& li {
				display: block;
				margin-right: 1rem;
				font-size: 1.4rem;
				line-height: 2.2rem;
				font-family: $body-font-family;
				font-weight: 400;
				letter-spacing: 0.3px;
			}
		}
	}
}

body.map-visible {
	.right_column {
		opacity: 1;
		pointer-events: all;

		@include MQ(1200px) {
			width: 1400px !important;
			min-width: 100%;
			margin: 0 auto;
		}

		.map__controls > * {
			pointer-events: all;
		}
	}

	.left_column {
		@include MQ(1200px) {
			display: none;
		}
	}
}

.container-xxl {
	@include media-breakpoint-up(xl) {
		max-width: unset;
	}
}

.dropdown-item {
	font-size: 1.4rem;
	text-decoration: none;
}

.noBcsCta {
	cursor: pointer;
	border-radius: 0.3rem;
	border: 0;
	display: inline-block;
	transition: all 0.15s ease;
	text-align: center;
	text-decoration: none;
	color: #fff;
	background-color: #f37ed5;
	line-height: 4.6rem;
	padding: 0 15px;
	margin-top: 2rem;

	&:hover {
		background-color: #f595dd;
	}
}

.desktopButtonWrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
	background-color: white;
	height: fit-content;
	margin: 1rem 0;
	border-radius: 25px;
	border: 1px solid #e9ebee;

	& button {
		margin: 0 !important;
		border-color: white !important;

		&.activeState {
			background-color: #e9e7ff;

			& span {
				font-weight: 700;
			}
		}
	}
}

.listing_page_header {
	place-items: flex-end;

	// @include media-breakpoint-up(xl) {
	// 	border-bottom: 1px solid map-get($map: $colours, $key: greyB);
	// }

	h1 {
		font-size: 2rem;
		line-height: 2.6rem;

		@include media-breakpoint-up(xl) {
			font-size: 2.4rem;
		}
	}

	.btn--dropdown {
		&[aria-expanded="true"] {
			border-radius: 21px 21px 0 0;
			border-bottom: 0;
		}
		color: #000000 !important;
	}

	.sortby-dropdown {
		button {
			width: 200px;
			text-align: left;
		}
	}

	.dropdown-menu {
		border-top: 0;
		border-color: map-get($map: $colours, $key: greyB);
		font-size: 1.4rem;
		line-height: 4.5rem;
		border-radius: 0 0 24px 24px;

		a {
			padding: 0 5px 0 20px;

			&:active {
				background-color: map-get($colours, purple);
				color: white;
			}
		}
	}
}

.listing_page_layout {
	.left_column {
		@include media-breakpoint-up(md) {
			.card--consultant {
				max-width: 400px;
				margin-left: auto;
				margin-right: auto;
			}
		}

		@include media-breakpoint-up(xl) {
			width: 650px;

			.card--business-center:last-of-type {
				margin-bottom: 0;
				border-bottom: 0;
			}

			.card--consultant {
				display: none;
			}

			> .row {
				margin: 0;
				//cols
				> div {
					padding: 0;
				}
			}
		}
	}

	.right_column {
		overflow: hidden;

		.card--consultant {
			margin-top: 15px;
			margin-left: 15px;
			max-width: 400px;
		}

		@include media-breakpoint-down(lg) {
			position: fixed !important;
			height: 100%;
			width: 100vw;
			top: 0;
			left: 0;
			z-index: 1031;
			opacity: 0;
			transition: opacity 0.15s ease-in-out;
			pointer-events: none;
		}

		@include media-breakpoint-up(xl) {
			height: 100vh;
			position: sticky;
			top: 0;
			width: calc(100% - 650px);
		}
	}

	.area_guide {
		strong,
		h1,
		h2,
		h3,
		h4,
		h5,
		h5 {
			
			font-family: $body-font-family;
			font-weight: 400;
			font-size: 1.4rem;
		}

		p {
			margin-top: 0;
		}

		ul {
			@extend .list-unstyled;
		}
	}

	@media only screen and (min-width: 1300px) {
		.left_column {
			width: 830px;
		}

		.right_column {
			width: calc(100% - 830px);
		}
	}

	.leaflet-popup-content {
		margin: 0;
	}

	.leaflet-popup-content-wrapper {
		padding: 0;
		overflow: hidden;
		border-radius: 4px;
	}

	.leaflet-popup .carousel-nav {
		> div {
			width: 30px;
			height: 30px;
		}
	}
}

.area-guide-h2 {
	font-size: 1.6rem;
	line-height: 4.3rem;
	
	font-family: $body-font-family;
	font-weight: 400;
}

.mobileActions {
	// top: 70px;
	// left: 0;
	// right: 0;
	// z-index: 1030;
	// font-size: 1.4rem;

	// > div {
	// 	@include Dropshadow(map-get($colours, purpleI), 25px, 0);
	// 	//width: 250px;
	// }

	position: sticky !important;
	z-index: 9;
	top: 60px;

	@include MQ(1200px) {
		top: 98px;
	}

	background-color: #f8f8f8;

	& :not(:first-child) {
		margin-left: 0.5rem;
	}

	button {
		// flex-grow: 1;
		// line-height: 46px;
		// text-indent: 35px;
		background-color: #e9e7ff;
		border: 1px solid #e9ebee;
		border-radius: 25px;
		padding: 1rem;
		margin: 1rem 0;

		&:hover {
			font-weight: 700;
		}

		@include MQ(1200px) {
			background-color: #ffffff;

			&:hover {
				background-color: #e9e7ff;
			}
		}

		> svg,
		img {
			width: 18px;
			top: 50%;
			transform: translate(0, -50%);
			left: 15px;
		}

		// + button {
		// 	border-left: 1px solid map-get($map: $colours, $key: greyB);
		// }
	}
}

#loading-spinner {
	position: absolute;
	top: 100px;
	left: 100px;
}

.loading {
	font-size: 50px;
	font-family: "eina";
	font-weight: 800;
	text-align: center;
	margin: 0 auto;
	padding-top: 250px;
	span {
		display: inline-block;
		margin: 0 -0.05em;
	}
}
.loading06 {
	span {
		position: relative;
		color: rgba(#000, 0.2);
		&::after {
			position: absolute;
			top: 0;
			left: 0;
			content: attr(data-text);
			color: #f37ed5;
			opacity: 0;
			transform: rotateY(-90deg);
			animation: loading06 4s infinite;
		}
		@for $i from 1 through 6 {
			&:nth-child(#{$i + 1})::after {
				animation-delay: #{$i * 0.2}s;
			}
		}
	}
}
@keyframes loading06 {
	0%,
	75%,
	100% {
		transform: rotateY(-90deg);
		opacity: 0;
	}
	25%,
	50% {
		transform: rotateY(0);
		opacity: 1;
	}
}

.loadMapButtonContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding-top: 250px;
}

.hideOnMobileBelowFold {
	//display: none;
}

.hideOnDesktopBelowFold {
	//display: none;
}

/*##### INTERNAL LINKING MODULE ######*/
.internal-links {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	/*background-color: #f2f2f2;*/
	// border: 1px solid red;
	width: 100%;
	margin-bottom: 1rem;

	&__header {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		flex-wrap: nowrap;

		&--left {
			width: 100%;

			@include MQ(768px) {
				width: 33%;
			}
		}

		&--right {
			//display: none;

			@include MQ(768px) {
				display: flex;
				width: 67%;
			}
		}
		padding: 1rem 0;
		border-bottom: 1px solid grey;
	}

	&__content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		flex-wrap: nowrap;

		@include MQ(768px) {
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
		}
	}

	&__container {
		// border: 1px solid blue;
		width: 100%;

		&:first-child {
			margin-bottom: 1rem;
		}

		@include MQ(768px) {
			width: 33%;
		}

		& ul {
			list-style-type: none;
			padding: 0;
			margin: 0;
		}

		& h4 {
			padding-bottom: 1rem;
			border-bottom: 1px solid grey;

			@include MQ(768px) {
				display: none;
			}
		}
	}

	&__link {
		margin: 0;
		padding: 1rem 0 0 0;
		font-size: 1.4rem;

		& a {
			text-decoration: none;

			&:hover {
				color: #5c489c;
				text-decoration: underline;
			}
		}
	}

	// @include MQ(1024px) {
	// 	.internal-links__container:first-child {
	// 		width: 100%;
	// 		min-width: 35%;
	// 	}

	// 	.internal-links__container:last-child {
	// 		width: 100%;
	// 		min-width: 65%;
	// 	}
	// }
}

/*##### INTERNAL LINKING MODULE ######*/

.bc-card-title {
	margin-bottom: 5px;
	font-size: 1.7rem;
	line-height: 2.5rem;
	font-family: $body-font-family;
	font-weight: 400;
	letter-spacing: 0.3px;
}

.default_carousel_image {
	visibility: visible;
	width: 100%;
	height: 255px;
	min-height: unset !important;
	max-height: 255px !important;
	// display: block;

	&__mobile {
		// display: block !important;
		// visibility: visible !important;

		// @include MQ(768px) {
		// 	display: none;
		// }
		display: block;

		@include MQ(768px) {
			display: none;
		}
	}

	&__desktop {
		display: none;

		@include MQ(768px) {
			display: block;
		}
	}
}

.default_carousel_panel {
	width: 100%;
	height: 325px;
	visibility: visible;
	background: #eeeaff;
}

@import "components/bc_card";
