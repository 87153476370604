@import "../abstracts/_colours.scss";

/////////// OUR STORY SECTION ///////////

.au-our-story {

    @include media-breakpoint-up(lg){
        padding-top: 100px;
        
        .os-scrollbar {
            display: none;
        }
    }

    @include media-breakpoint-down(lg){
        padding-bottom: 71px;
    }

    @include media-breakpoint-down(sm){
        padding-bottom: 60px;
    }

    position: relative;
    background: linear-gradient(180deg, $white 0%, map-get($colours, greyC) 100%);

    .our-story-container {
        padding-right: 50px;
    }

    .au-os {
        font-size: 1.2rem;
        line-height: 2rem;
        > div {
            position: relative;
        }
    } 

    @include media-breakpoint-down(sm){
        .os-content > div > div {
            width: 720px;
        }
    }

    @include media-breakpoint-between(sm,md){
        .os-content > div > div {
            width: 1170px;
        }
    }

    @include media-breakpoint-down(md) {
        .of-mobile-line {
            position: relative;
            padding-top: 60px;
            margin-left: -15px;
            font-family: 'maison_neue_demi';
            font-size: 1.5rem;
            &:before {
                content: "";
                position: absolute;
                bottom: 40px;
                width: 95%;
                height: 5px;
                background: map-get($colours,purple);
            }

            div {
                color: map-get($colours,purple);

                &:not(:last-child):before {
                    content: "";
                    position: absolute;
                    bottom: 33px;
                    width: 19px;
                    height: 19px;
                    border: 2px solid $white;
                    border-radius: 100%;
                    background: map-get($colours,purple);
                }
            }
        }
    }

    @include media-breakpoint-down(sm){
        .au-os {
            img {
                width: 71px;
                height: 71px;
            }
        }

        .au-os-1 {
            padding-left: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .au-os-1,
        .au-os-2 {
            top: 415px;
        }

        .au-os-3 {
            top: 376px;
        }

        .au-os-4 {
            top: 326px;
        }

        .au-os-5 {
            top: 225px;
        }
    }

    @include media-breakpoint-up(lg) {
        .au-os-1,
        .au-os-2 {
            top: 345px;
        }

        .au-os-3 {
            top: 286px;
            left: 40px;
        }
        .au-os-4 {
            top: 286px;
            left: 60px;
        }

        .au-os-5 {
            top: 205px;
            left: 60px;
        }

        .au-os-5 {
            top: 30px;
            left: 40px;
        }
    }

    @include media-breakpoint-up(xl) {
        .au-os-1 {
            top: 435px;
            left: 5px;
            padding-right: 4%;
        }

        .au-os-2 {
            top: 435px;
            left: 16px;
            padding-right: 3.6%;
        }

        .au-os-3 {
            top: 375px;
            left: 68px;
            padding-right: 4%;
        }

        .au-os-4 {
            top: 340px;
            left: 75px;
            padding-right: 6%;
        }

        .au-os-5 {
            top: 225px;
            left: 95px;
            padding-right: 5%;
        }

        .au-os-6 {
            top: 0px;
            left: 62px;
            padding-right: 4%;
        }
    }
}

@include media-breakpoint-up(md){
    #au-copy {
        position: relative;
        top: 300px;
        margin-top: -300px;
    }
}

.au-story-curve-img {
    padding-bottom: 0;
}

@include media-breakpoint-up(md){
    .au-story-curve {
        padding: 0 15px;
    }
}

.au-story-curve-img {
    width: 100%;
    padding-bottom: 100px;
}

@include media-breakpoint-down(md){
    .our-story-container {
        padding-top: 55px;
        padding-left: 40px;
        padding-right: 70px !important;
        margin-bottom: 30px;
    }

    .au-our-story {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .container {
            max-width: 100%;
            margin-left: 0 !important;
            margin-right: 0 !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            overflow-x: hidden;

            > .au-story-curve {
                padding: 0 35px;
                display: flex;
                flex-wrap: nowrap;
                overflow-x: scroll;
                overflow-y: hidden;

                > .row {
                    min-width: 1110px;
                }
            }
        }
    }

    .au-story-curve-img {
        width: auto;

        @include media-breakpoint-up(lg){
            width: 1110px;
        }
    }
}

@include media-breakpoint-down(sm){
    .our-story-container {
        padding-top: 55px;
        padding-left: 20px;
        padding-right: 20px !important;
    }
}

/////////// MAJOR PROVIDERS SECTION ///////////

.au-providers {
    z-index: 200;
    background: $white;

    .col-left {
        width:100%;
        z-index: 10;
        
        @include media-breakpoint-up(md){
            position: absolute;
            width: 345px;
            top: 0;
            right: 50%;
        }

        @include media-breakpoint-up(lg){
            width: 465px;
            right: 50%;
        }

        @include media-breakpoint-up(xl){
            width: 50%;
            left: 0;
        }

        &.col-fixed {
            position: fixed;
        }

        .au-providers-desc {
            padding: 38px 30px 0 30px;
            max-width: 580px;
            margin: 0 auto;
            
            @include media-breakpoint-up(md){
                padding: 0 40px 76px 0;
                max-width: 360px;
                margin-left: 0;
            }

            @include media-breakpoint-up(lg){
                padding: 0 46px 76px 0;
                max-width: 445px;
            }

            @include media-breakpoint-down(lg){
                p {
                    margin-top: 0;
                    margin-bottom: 35px
                }
            }

            @include media-breakpoint-up(xl){
                padding: 0 172px 76px 15px;
                max-width: 585px;
                margin-right: 0;
                margin-left: auto;
            }
        }

        h2 {
            margin-top: 12px;
        }

        p {
            margin-bottom: 23px;
        }

        @include media-breakpoint-up(md){
            &::before,
            &::after  {
                content: "";
                display: block;
                position: absolute;
                left: 100%;
                right: 0;
                width: 100%;
                height: 188px;
                z-index: 10;
            }

            &::after {
                bottom: 0;
                background-image: linear-gradient(180deg,  rgba(255,255,255,0.00) 31%, $white 100%);
            }

            &::before {
                top: 0;
                background-image: linear-gradient(180deg, $white 31%, rgba(255,255,255,0.00) 100%);
            }
        }
    }

    .col-right {
        @include media-breakpoint-up(md){
            position: relative;
            width: 50%;
            left: 50%;
        }

        img {
            width: 100%;

            @include media-breakpoint-up(md){
                width: 808px;
            }
        }
    }
    
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    
    &::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }

    @include media-breakpoint-up(sm){
        // height: 874px;
        overflow-x: hidden;
        overflow-y: scroll;
    }
}