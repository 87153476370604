$tablet_content_break_width: 768px;
$small_dekstop_content_break_width: 991px;
$dekstop_content_break_width: 1024px;

.listingspageUsps {
	display: none;
	position: relative;
	height: 100%;
	width: 100%;
	margin: 0 auto 2rem auto;
	background-color: #f5f6fa;

	// border: 1px solid red;

	@include MQ($tablet_content_break_width) {
		height: 65px;
	}

	@include MQ($dekstop_content_break_width) {
		display: block;
	}

	&__container {
		width: 100%;
		height: 100%;
		max-width: 1000px;
		margin: 0 auto;

		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;

		@include MQ($tablet_content_break_width) {
			display: flex;
			flex-direction: row;
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}

	&__usp {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex-wrap: nowrap;

		max-width: 400px;
		height: 35px;
		// border: 1px solid pink;
		padding: 0;
		margin: 0.75rem 0;

		@include MQ($tablet_content_break_width) {
			height: 65px;
		}

		& img {
			padding: 0;
		}

		& p {
			font-size: 1.6rem;
			color: #042040 !important;
		}

		@include MQ($tablet_content_break_width) {
			&:not(:last-child) {
				margin: 0 1.5rem 0 0;
			}
		}
	}

	&__pipe {
		content: url("/App_Themes/images/ListingPageUspSVG/Line\ 34.svg");
		width: 1px;
		height: 30px;
		margin: 0 4rem;
		// border: 1px solid blue;
	}
}
