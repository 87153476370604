@mixin iconLinkBase {
    position: relative;
    height: 41px; 
    display: inline-block;
    line-height: 41px;
    padding-left: 56px; 
    font-size: 1.5rem;
}

.icon-link {
    &--play {
        @include iconLinkBase();
        color: map-get($map: $colours, $key: grey);
        &:hover {
            &::before {background-image: url('#{map-get($appPaths, "images")}/icons/play.svg');
                
            }
        }
        &::before {
            transition: background 0.15s ease;
            background-size: contain;
            content: "";
            width: 41px;
            height: 41px;
            position: absolute;
            left: 0;
            background-image: url('#{map-get($appPaths, "images")}/icons/play-hover.svg');
        }
    }
}