@import "../abstracts/_colours.scss";
@import "../abstracts/_settings.scss";

$iconRadius: 30px;

@mixin icon {
    width: $iconRadius;
    height: $iconRadius;

    @media only screen and (min-width: 440px) {
        width: $iconRadius + 5;
        height: $iconRadius + 5;
    }
}

body.searchOpen {

    #site-header {
        button.js-search {
            background-color: map-get($colours, purpleC);
            background-image: url('#{map-get($appPaths, "images")}/icons/close-mob-nav.svg');
            background-size: 38%;
        }

        #search-container {
            bottom: -68px;

            @include media-breakpoint-up(md) {
                bottom: -78px;
            }

            @include media-breakpoint-up(lg) {
                bottom: -76px;
            }
        }
    }
}

#site-header {
    line-height: 2.9rem;
    z-index: 1000;
    height: 60px;
    font-size: 1.5rem;
    top: 0;
    transition: height 0.2s linear;

    .location_searcher .results a {
        text-decoration: none;
    }

    a.phone {
        @include icon();
        background-image: url('#{map-get($appPaths, "images")}/new-header-phone-icon.svg');
        background-size: auto;
		background-repeat: no-repeat;
		background-position-y: center;
        text-indent: 1000px;
        font-weight: 700;
		color: #042040;

    }

    button.js-search {
        @include icon();
		background-color: map-get($map: $colours, $key: purple);;
        background-image: url('#{map-get($appPaths, "images")}/Icon-MagnifyingGlass.svg');
        border: 1px solid map-get($map: $colours, $key: purple);;
        border-radius: 50%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 65%;
        transition: all 0.15s linear;
    }

    a.favourites {
        @include icon();
        border: 1px solid map-get($map: $colours, $key: greyI);
        border-radius: 50%;
        padding: 8px 7px 7px 7px;
        display: none;

        @media only screen and (min-width: 440px) {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        svg {
            path {
                fill: map-get($colours, red);
            }
        }

        span.counter {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            font-size: 1.1rem;
            font-weight: 700;
            line-height: 2rem;
            right: -8px;
            top: -7px;
        }
    }

    @include media-breakpoint-up(md) {
        height: 80px;
    }
	/*Changed to accomodate 4th nav col - 260423*/
    @include media-breakpoint-up(xl) {
        height: 100px;

        a.phone {
            // background-image: none;
            border-radius: unset;
            width: unset;
            height: unset;
            text-indent: 0;
            // border-left: 1px solid map-get($map: $colours, $key: greyB);
        }
    }
}

#header-main {
    z-index: 2;
    border-bottom: 1px solid map-get($map: $colours, $key: greyB);

    .container-fluid {
        z-index: 2;
    }

    button.js-search {

        @media only screen and (min-width: 1400px) {
            /*border: 100px solid red;*/
            display: none !important;
        }
    }

    .location_searcher--b {

        input[type="search"] {

            &::placeholder {
                color: #042040;
            }
        }
    }
}

#search-container {
    z-index: 1;
    transition: bottom 0.2s linear;
    bottom: 0;
}

.header_inline_search{
    //display:none;
    opacity: 0;
    width: 0;
    transition: all 0.15s linear;
    @media only screen and (min-width: 1420px) {
        display: block;
        opacity: 1;
        width: 340px;
    }
}

@import "../layout/_desktopNavigation.scss";