// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
// .container {
//   max-width: $max-width; /* 1 */
//   margin-left: auto; /* 2 */
//   margin-right: auto; /* 2 */
//   padding-left: 20px; /* 3 */
//   padding-right: 20px; /* 3 */
//   width: 100%; /* 1 */
//  }



/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}


textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus,
.uneditable-input:focus {   
  outline: 0 none;
}

.container-right-half {
  margin-right: auto;
  margin-left: 0;
}
@include media-breakpoint-down(sm) {
  .container-left-half {
    padding-right: 0;
    padding-left: 0;
  }
}

@include media-breakpoint-between(sm,md){
  .container-left-half {
    max-width: 540px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .hero-left {
    justify-content: center;
  }
}
@include media-breakpoint-up(md){
  .container-left-half,
  .container-right-half {
    width: 345px;
  }
  .container-left-half {
    margin-right: 0;
    margin-left: auto;
  }
  .hero-left {
    padding-right: 0;
  }
}
@include media-breakpoint-up(lg){
  .container-left-half,
  .container-right-half {
      width: 450px;
  }
}
@include media-breakpoint-up(xl){
  .container-left-half,
  .container-right-half {
      width: 555px;
  }
}

// Responsive width class
@each $breakpoint in map-keys($grid-breakpoints) {
  @each $size, $length in $sizes {
    @include media-breakpoint-up($breakpoint) {
      .w-#{$breakpoint}-#{$size} {width: $length !important;}
    }
  }
}

.ofFadeIn {
  opacity: 0;
  transition: transform 1.5s, opacity 1.5s;
  transform: translate3d(0px, 100px, 0px);
}

.ofFadeIn.visible {
  opacity: 1;
  transform: translate3d(0px, 0px, 0px);
  transform-style: preserve-3d;
}