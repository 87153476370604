.ml-5 {
	margin-left: 0rem !important;
}
// #site-header__content .btn--pink {
// 	background-color: #5f34c1;
// 	border-radius: 10px;
// }
.ppcHeroWithForm {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 0;

	.container {
		max-width: 1400px;
		padding: 0;
		display: flex;
		flex-wrap: wrap;

		@include MQ(1024px) {
		}
	}

	.row {
		width: 100%;
		margin: 0 auto;
	}

	&__left,
	&__right {
		flex: 1 1 100%;
		width: 100%;
		padding: 0;
		background-color: inherit;
		//border-radius: 25px; // Mobile border radius
	}

	&__left {
		flex-wrap: wrap-reverse;
		display: flex;
		justify-content: center;

		max-width: 100%;
		margin: 1rem auto;
		padding: 0.75rem;
		position: absolute;

		@include MQ(500px) {
			max-width: 100%;
			margin: 1rem auto;
		}

		h1,
		p {
			margin: 0;
			color: #020202;
		}

		h1 {
			text-align: center;
			padding-bottom: 1rem;
			color: #ffffff;

			@include MQ(1024px) {
				text-align: left;
				color: #180d2f;
			}
		}

		h2 {
			color: #180d2f;
			text-align: center;
			padding: 2rem;
			line-height: 4rem;
			font-size: 2.5rem;
			
			@include MQ(500px) {
				font-size: 2.5rem;
			}

			@include MQ(768px) {
				font-size: 3.5rem;
			}

			@include MQ(1024px) {
				text-align: left;
				padding: 2rem;
				line-height: 4rem;
			}
		}

		
	}

	.ppcHeroWithForm__usps {
		display: none;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;
		background-color: #f8f4e8;
		border-top-left-radius: 25px;
		border-bottom-left-radius: 25px;
		padding: 10px 0;
		max-width: 600px;

		&.mobile {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 0rem auto 0 auto;

			@include MQ(768px) {
				margin: 2rem auto 0 auto;
			}

			@include MQ(1024px) {
				display: none;
			}

			& .usp {
				@include MQ(455px) {
					width: 47%;
				}
			}
		}

		&.desktop {
			display: none;

			@include MQ(1024px) {
				display: flex;
			}

			& .usp {
				width: 47%;
			}
		}

		.usp {
			display: flex;
			align-items: center;
			padding: 10px;
			border: 1px solid #f8f4e8;
			border-radius: 25px;
			height: fit-content;
			max-height: 100px;

			@include MQ(1024px) {
				max-height: 75px;
			}

			@include MQ(1200px) {
				max-height: 100px;
			}

			&__icon {
				width: 50px;
				height: 50px;
				margin-right: 10px;
				background-color: #f8f4e8; // Placeholder color

				img {
					width: 50px;
					height: 50px;
				}
			}

			&__content {
				flex: 1;
				p {
					font-size: 1.7rem;
					line-height: 2.2rem;
				}
			}
		}
	}

	&__right {
		&__container {
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
			min-height: 550px;

			@include MQ(1024px) {
				border-radius: 25px;
			}

			&__title {
				text-align: center;
				color: #ffffff;
				font-size: 3rem;
				padding: 10rem 0 0 0;

				@include MQ(1024px) {
					font-size: 4rem;
				}

				p {
					font-weight: bold;
					margin: 1.5rem 0 2.5rem 0;
					@include MQ(1024px) {
						margin: 1.5rem 0 0 0; // Adjust margin as needed
					}
				}
			}

			&__subtext {
				text-align: center;
				color: #ffffff;
				font-size: 2.5rem;
				padding: 0 0 3rem 0;

				p {
					font-weight: bold;
					margin: 1.5rem 0 0 0; // Adjust margin as needed
				}

				& h2 {
					color: #ffffff;
					margin-top: 2rem;
				}
			}

			& form {
				padding: 10rem 1rem 0 1rem;

				@include MQ(1024px) {
					padding: 3rem;
				}

				& label {
					display: none;
					color: #ffffff;
				}

				& .btn--pink {
					cursor: pointer;
					border-radius: 10px;
					border: 0;
					display: inline-block;
					transition: all 0.15s ease;
					text-align: center;
					text-decoration: none;
					color: #4d3197;
					background-color: #ffffff;
					width: 50% !important; // Set the button width to 50%
					max-width: 300px;
					font-weight: bold; // Make the text bold
				}
			}

			.formPPCButton {
				display: flex;
				justify-content: center;
				flex: 0 0 100%; // Set container flex properties
				max-width: 100%; // Set container max-width
			}
		}
	}

	@include MQ(768px) {
		.ppcHeroWithForm__usps {
			display: flex;
			justify-content: center;
			// flex-direction: column;
			gap: 10px;
			margin-top: 1rem;
			border-top-left-radius: 25px;
			border-top-right-radius: 25px;
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 25px;
		}

		&__left {
			flex-wrap: wrap;
			padding: 2rem 0px 20px 0px;
		}
		&__right {
			// padding: 20px 30px 20px 0px;
		}
	}

	@include MQ(1024px) {
		flex-wrap: nowrap;

		.ppcHeroWithForm__usps {
			flex-direction: row;
			flex-wrap: wrap;
			border-top-left-radius: 25px;
			border-top-right-radius: 0;
			border-bottom-left-radius: 25px;
			border-bottom-right-radius: 0;
			max-width: unset;
		}

		&__left {
			flex: 1 1 50%;
			padding: 2rem 0px 20px 30px;
			border-radius: 25px 0 0 25px; // Desktop border radius for left

			padding: 7rem 0px 20px 30px;
			display: block;
			position: relative;

			h1,
			h2 {
				margin-bottom: 10px;
			}

			h1 {
				font-size: 2.25rem;
				line-height: 3.5rem;
				font-weight: 700;
				padding: 1rem 1rem 1rem 0.5rem;
			}

			h2 {
				font-size: 4.4rem;
				line-height: 5rem;
				padding: 0;
			}
		}

		&__right {
			flex: 1 1 50%;
			border-radius: 25px; // Desktop border radius for right
			padding: 20px 30px 20px 0px;
			&__container {
				min-height: 675px;
			}
		}
	}

	@include MQ(1200px) {
		.ppcHeroWithForm__usps {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 10px;

			& .usp {
				width: 100%;
			}
		}

		&__left {
			flex: 1 1 50%;
			border-radius: 25px 0 0 25px; // Desktop border radius for left

			h1,
			h2 {
				margin-bottom: 10px;
			}

			h1 {
				font-size: 2.5rem;
				line-height: 3.5rem;
				font-weight: 700;
				padding: 2rem 1rem 3rem 0.5rem;
			}

			h2 {
				font-size: 5.4rem;
				line-height: 7rem;
				padding: 0;
			}
		}
	}

	@include MQ(1400px) {
		.ppcHeroWithForm__usps {
			display: grid;
			grid-template-columns: 1fr 1fr;
			gap: 10px;
		}

		&__left {
			flex: 1 1 50%;
			border-radius: 25px 0 0 25px; // Desktop border radius for left

			h1,
			h2 {
				margin-bottom: 10px;
			}

			h1 {
				font-size: 2.5rem;
				line-height: 3.5rem;
				font-weight: 700;
				padding: 2rem 1rem 3rem 0.5rem;
			}

			h2 {
				font-size: 6.5rem;
				line-height: 7rem;
			}
		}
	}
}

._js_propertycardswithlocations {
	.card__content {
		padding: 1rem; // Adjust padding as needed

		h3 {
			// Styles for h3 if needed
		}

		ul {
			padding: 1rem 0 !important; // Set ul padding to 0 and mark as important
			margin: 0;
			list-style: none; // Remove default list styling

			li {
				font-weight: bold;
				position: relative;
				padding-left: 25px; // Adjust padding to make room for the icon
				color: #79756a; // Set li color to #79756a

				&::before {
					content: "";
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
					width: 20px; // Adjust size as needed
					height: 20px; // Adjust size as needed
					background-image: url("/media/0oecfh51/2.svg");
					background-size: contain;
					background-repeat: no-repeat;
				}
			}
		}
	}
}
