$colours : (
  white : #fff,
  black : #000,

  pink : #f37ed5,
  pinkB : #ba3a80,
  pinkC : #ffeff7,
  pinkD : #f196e4,
  pinkE : #ffb0f4,

  purple : #5f34c1,//#5C489C,
  purpleB : #8462EF,  
  purpleC : #e9e7ff,
  purpleD : #faf9ff, 
  purpleE : #4a3a7f,
  purpleF : #C6B5FD,
  purpleG : #f4f2fe,
  purpleH : #ECE8FF,
  purpleI : #C3BDEA,
  purpleJ : #eae6ff,
  purpleK : #f3f1ff,
  purpleL : #DDD6FF,
  purpleM : #D3CEF0,
  purpleN : #E9E7FF,
  purpleO : #F2F0FF,
  purpleP : #5C489E,
  purpleQ : #46377c,
  purpleR: #f0e8ff,

  grey : #4c545d,
  greyB : #e2e5e8, 
  greyC : #fafafa,
  greyD : #f8fdff,
  greyE : #fcfcfe,
  greyF : #f5f6fa,
  greyG : #E2E5E8,
  greyH : #f4f6f9,
  greyI : #DEE1E4,
  greyJ : #505A64,
  greyK : #efebff,
  greyCro: #4C545D,

  blue : #042040,
  blueB : #3F5DB5,
  blueC : #96AAC9,
  blueCro: #042040,

   
  cyan : #7BFCC1,
  cyanB : #6affd1,

  red : #FF4747, 
  
  brown : #826134,
  brownB : #fcece3,
  brownC : #a14427,

  orange : #F9963C,
  orangeB : #FFCB02,
  orangeC : #FFC79D,

  yellowA : #ffdc54,

  greenA : #4c544c,
  greenB : #549F41,
  greenC : #2F6F1F,
  greenD : #78B700,
  greenE : #51A401,
  greenF : #188600
);