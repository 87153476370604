﻿@import "../abstracts/_colours.scss";
$quSize: 30px;

.tooltip {

    &--question {
        display: inline-block; 
        width: $quSize;
        height: $quSize;
        background-color: map-get($colours, purple);
        line-height: $quSize;
        color: white;
        border-radius: 50%;
        cursor: help;
        text-align:center;
        &::after {
            content: "?";
        }
    }
}