@import "../abstracts/_colours.scss";

#site-footer {
    font-size: 1.4rem;
    line-height: 2.4rem;

    h5 {
        font-family: 'maison_neue_book';
        font-size: 1.4rem;
        font-weight: 600;
        letter-spacing: 0.3px;
    }

    a {
        color: inherit;
        text-decoration: none;
    }


    &__lower {
        ul {
            list-style: none;
            place-items: left;

            img {
                max-height: 70px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        #site-footer__upper {
            .row {
                > div {
                    + div {
                        .accordion__header {
                            border-top: 0;
                        }
                    }
                }
            }
        }
		#site-footer__lower {
            .row {
                > div {
                    + div {
                        .accordion__header {
                            border-top: 0;
                        }
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(md) {
        #site-footer__upper {
            div.accordion--themeB {
                .accordion__header {
                    border: 0;
                }
            }
        }
		#site-footer__lower {
            div.accordion--themeB {
                .accordion__header {
                    border: 0;
                }
            }
        }
    }
}