@mixin breadcrumbBase(){
    padding: 0;
    margin: 0; 
    display: block;
    list-style: none;
    font-size: 1.3rem;
    ol {
        padding: 0;
        margin: 0;
    }
    a {
        // color: map-get($map: $colours, $key: purple);
        color: map-get($map: $colours, $key: greyCro);
        // color: #4C545D;
		text-decoration: none;
    }
    li {
        display: inline; 
        + li {
            position: relative;
            margin-left: 20px;
            &::before {
                content: '/';
                position: absolute;
                left: -15px;
            }
        }
    }
}

.breadcrumbs {
    @include breadcrumbBase();

    &--listing {
        li {
            
            + li::before {
                left: -15px;
                content: '';
				width: 7px;
				height: 14px;
                background-image: url('#{map-get($appPaths, "images")}/OF-Breadcrumb-arrow.png');
				background-repeat: no-repeat;
                top: 2px;
            }
        }
    }
}