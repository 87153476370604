$spacer: 1rem !default;
$spacers:
  (   
    x1: $spacer,
    x1-5: $spacer * 1.5,
    x2: ($spacer * 2),
    x2-5: $spacer * 2.5,
    x3: ($spacer * 3), 
    x3-5: $spacer * 3.5,
    x4: ($spacer * 4), 
    x4-5: $spacer * 4.5,
    x5: ($spacer * 5),
    x5-5: $spacer * 5.5,
    x6: ($spacer * 6),
    x6-5: $spacer * 6.5,
    x7: ($spacer * 7), 
    x7-5: $spacer * 7.5,
    x8: ($spacer * 8), 
    x8-5: $spacer * 8.5,
    x9: ($spacer * 9), 
    x9-5: $spacer * 9.5,
    x10: ($spacer * 10), 
    x10-5: ($spacer * 10.5), 
    x11: ($spacer * 11), 
    x11-5: ($spacer * 11.5), 
    x12: ($spacer * 12), 
  );