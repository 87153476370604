.logoBase {
    background-image: url('#{map-get($appPaths, "images")}/brand/logo.svg');
    text-indent: -9999px;
    overflow: hidden;
    background-repeat: no-repeat;
    //transition: all 0.15s ease;
}

.logo {
    &--header {
        @extend .logoBase;
        width: 180px;
        height: 40px;
        background-size: cover;
        transition: all 0.15s linear;

        @media only screen and (min-width: 350px) {
            width: 200px;
            height: 45px;
        }
/*
        @include media-breakpoint-up(sm) {
            width: 240px;
            height: 38px;
        }*/

        @include media-breakpoint-up(lg) {
            width: 240px;
            height: 52px;
        }
    }

    &--footer {
        @extend .logoBase;
        height: 42px;
    }

}
