.newBcRowContainer {
	margin-left: unset !important;
	margin-right: unset !important;
	display: flex;
	flex-direction: row;
	justify-content: center;
}
.listingPageContainertOverideClass {
	@include MQ(768px) {
		max-width: unset;
	}
}
.resultsLeftOverideClass {
	width: 100%;
	//margin-top: 0 !important;
	@include MQ(1300px) {
		width: 65% !important;
	}
}
.resultsRightOverideClass {
	@include MQ(1300px) {
		width: 35% !important;
	}

	@include MQ(1600px) {
		width: 50% !important;
	}

	@include MQ(1600px) {
		width: 60% !important;
	}
}
.newBcCardContainer {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	flex-wrap: wrap;
	max-width: 100%;
	margin: 0.5rem 0;

	@include MQ(768px) {
		max-width: 50%;
		margin: 0 0 2rem 0;
	}

	// @include MQ(1300px) {
	// 	width: 50%;
	// }
}
.bc_card {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex-wrap: nowrap;

	width: 100%;
	max-width: 500px;
	height: 100%;
	margin: 0 0 0.5rem 0;

	transition: box-shadow 0.3s;

	border-radius: 10px;

	position: relative;

	@include MQ(768px) {
		width: 97%;
		align-items: center;
	}
	box-shadow: 0 0 3px rgba(33, 33, 33, 0.2);
	&:hover {
		box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
	}

	&__tags_container {
		width: 100%;

		&__tag {
			border: 0;
			border-radius: 5px;
			top: 10px;
			left: 10px;
			padding: 6px 12px;
			font-size: 1.4rem;

			&--managed {
				color: #034f3c;//#02755a;
				background-color: #35ffd3;
			}

			&--serviced {
				color: #000000;
				background-color: #ffffff;
			}

			&--coworking {
				color: #ffffff;
				background-color: #5C489C;
			}

			// &--offer {

			// }
		}

		&--offer_text {
			bottom: 67%;

			@include MQ(350px) {
				bottom: 62%;
			}

			@include MQ(410px) {
				bottom: 59%;
			}

			@include MQ(465px) {
				bottom: 55%;
			}

			@include MQ(500px) {
				bottom: 53%;
			}

			@include MQ(768px) {
				bottom: 54%;
			}

			@include MQ(821px) {
				bottom: 52%;
			}

			@include MQ(1024px) {
				bottom: 48%;
			}

			@include MQ(1200px) {
				bottom: 50%;
			}

			@include MQ(1300px) {
				bottom: 48%;
			}

			// bottom: 48%;
		}
	}

	&__carousel {
		width: 100%;

		& .splide__track {
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;

			& .splide__list {
				& img {
					width: 100%;
					/* width: 200px; */
					height: 185px;
					object-fit: fill;

					@include MQ(350px) {
						height: 225px;
					}

					@include MQ(410px) {
						height: 255px;
					}

					@include MQ(465px) {
						height: 300px;
					}

					@include MQ(500px) {
						height: 325px;
					}

					@include MQ(768px) {
						height: 275px;
					}

					@include MQ(1024px) {
						height: 325px;
					}
				}
			}
		}
	}

	&__content {
		// border: 1px solid red;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-wrap: nowrap;

		& a {
			text-decoration: none;
			//height: 100%;
			min-height: 58px;
		}

		&__title {
			// border: 1px solid green;

			padding: 1.5rem 1rem 0 1rem;

			& h2,
			h4 {
				font-size: 1.5rem;
			}

			& h2 {
			}

			& h4 {
			}
		}

		&__travel {
			// border: 1px solid blue;

			padding: 0.5rem 1rem 0 1rem;

			& .stations {
				margin: 0;
				padding: 0;

				& li {
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: center;
					flex-wrap: nowrap;
				}
			}

			& ul {
				& li {
					list-style-type: none;
					font-size: 1.3rem;

					& svg {
						width: 17px;
						height: 17px;
						margin-right: 1rem;
					}
				}
			}
		}

		&__details {
			// border: 1px solid purple;

			padding: 0.5rem 1rem 0 1rem;

			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: stretch;
			flex-wrap: wrap;

			&__sizes {
				width: 100%;
				min-height: 58px;

				& p {
					font-size: 1.4rem;
				}

				&__people {
					margin: 0 0 0 3px;
					&::before {
						content: url(../../../App_Themes/images/new-svg-icons/of-people.svg);
						position: relative;
						left: -2px;
						top: 3px;
					}
				}

				&__size {
					margin: 0 0 0 4px;
					&::before {
						content: url(../../../App_Themes/images/new-svg-icons/of-size.svg);
						position: relative;
						left: -4px;
						top: 3px;
					}
				}
			}

			&__price {
				width: 100%;
				text-align: right;
				font-size: 1.35rem !important;
				color: #042040;

				&--rent-from {
					font-weight: 600 !important;
				}

				&--rent-cost {
					font-size: 1.8rem !important;
					font-weight: bold !important;

					@include MQ(768px) {
						font-size: 2rem !important;
					}

					@include MQ(1024px) {
						font-size: 2.4rem !important;
					}
				}

				&--pp-pm {
					font-size: 1.2rem !important;
				}

				&--call {
					// display: flex;
					// width: 100%;
					// flex-direction: row;
					// justify-content: flex-end;
					// align-items: center;
					color: #042040;
					// flex-wrap: nowrap;

					& a {
						color: #042040;
						
					}
				}
			}

			& a {
				width: 100%;
			}
		}

		&__buttons {
			// border: 1px solid pink;

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: 0.5rem 0 1rem 0;

			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;

			@include MQ(768px) {
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-start;
				padding: 0 1rem 1rem 1rem;
			}

			& :not(:last-child) {
				margin-bottom: 1rem;

				@include MQ(768px) {
					margin-bottom: unset !important;
				}
			}

			&--grey-button {
				background-color: #ffffff;
				color: #595959;//#9d9d9d;
				cursor: pointer;
				border: 1px solid;
				border-color: #9d9d9d;
				display: inline-block;
				transition: all 0.15s ease;
				text-align: center;
			}

			& a {
				width: 100%;
				border-radius: 8px;
				font-weight: bold;

				min-height: 48px;

				@include MQ(768px) {
					width: 48%;
				}
			}
		}
	}
}
