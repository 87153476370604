@import "../abstracts/_colours.scss";
// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  min-width: 100%;
  position: relative;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
}

body {
  height: 100%;
  overflow-x:hidden; 
}

figcaption{font-size: 1.1rem; line-height: 1.9rem;}
/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

/**
 * Basic styles for links
 */
a {
  color: map-get($map: $colours, $key: grey);
  //text-decoration: none;
  // @include on-event {
  //   text-decoration: underline;
  // }
}
button{
  cursor: pointer;
}
.btn:focus,.btn:active, a:focus, a:active {
  outline: none !important;
  box-shadow: none;
}

input::-webkit-input-placeholder {
  line-height: normal!important;
}

hr {
  height: 1px;
  background-color: $border-color;
  margin-top: 40px; 
  margin-bottom: 40px;
  border: 0;
  
  @include media-breakpoint-up(lg){
    margin-top: 60px; 
    margin-bottom: 60px;
  } 

  &.hr {
    &--noMar {
      margin: 0;
    }
  }
}