@import "../abstracts/_colours.scss";
#desktopNav {
	a {
		color: map-get($map: $colours, $key: blue);
		text-decoration: none;
	}
	ul {
		list-style: none;

		li {
			padding: 20px 0;
			+ li {
				margin-top: 0;
				margin-left: 15px;

				@include media-breakpoint-up(xl) {
					margin-left: 25px;
				}
			}
		}

		.dropdown {
			position: relative;
			padding-right: 20px;
			> a {
				display: block;
			}
			img {
				position: absolute;
				top: 28px;
				right: 0;
			}

			&:hover {
				.dropdown__menu {
					display: block;
				}
			}

			&__menu {
				@extend .shadowA;

				display: none;
				width: 330px;
				background: map-get($map: $colours, $key: white);
				border-radius: 4px;
				top: 60px;
				left: -85px;
				z-index: 1;

				h5 {
					line-height: 40px;
					padding-left: 7px;
					text-transform: uppercase;
					font-size: 1.2rem;
				}

				p {
					line-height: 40px;
					padding-left: 7px;
					text-transform: uppercase;
					font-size: 1.2rem;
					font-family: $header-font-family;
					font-weight: 700 !important;
					margin: 0;
					color: #042040;
					// font-weight: 500;
				}

				&--wide {
					width: 550px;
				}

				&--xtra_wide {
					width: 550px;

					> .row {
						> div {
							&:nth-child(n + 4) {
								margin-top: 15px;
							}
						}
					}

					@include media-breakpoint-up(xl) {
						width: 700px;

						> .row {
							> div {
								&:nth-child(n + 4) {
									margin-top: 0;
								}

								&:nth-child(n + 5) {
									margin-top: 15px;
								}
							}
						}
					}
				}

				&::before,
				&::after {
					pointer-events: none;
					position: absolute;
					background: inherit;
					content: "";
				}

				&::before {
					@extend .shadowA;
					width: 15px;
					height: 15px;
					top: -5px;
					left: 115px;
					transform: rotate(45deg);
					z-index: 1;
				}

				&::after {
					width: 95%;
					height: 20px;
					top: 0px;
					left: 3px;
					z-index: 3;
				}

				a {
					padding: 0 7px;
					&:hover {
						background-color: map-get($colours, purpleG);
						font-weight: 600;
					}
				}

				ul {
					li {
						line-height: 4rem;
					}
				}
			}
		}
	}
}
