.cm_caseStudyCarousel {

    background-image: linear-gradient(156deg,#604C93 4%,#32255A 89%);

    img {
        min-width: unset;
        min-height: unset;
        max-width: 300px;
    }

    .splide__pagination {
        position: static;
        padding: 25px 0;
        border-top: 1px solid rgba(130,106,193,0.3);
        border-bottom: 1px solid rgba(130,106,193,0.3);
        margin-top: 50px;

        button {
            margin: 0;
            width: 12px;
            height: 12px;
            background-color: #a89cc8;

            &.is-active {
                transform: scale(1);
                background-color: white;
            }
        }

        li + li {
            margin-left: 5px;
        }
    }

    .case-study {
        place-content: center;
        a {
            color: inherit;
            right: 0;
            bottom: 0;
            svg {
                top: 0;
                right: 0;
            }
        }
    }
}