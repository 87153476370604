#photo-section{
    .items {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
        row-gap: 10px;
       
        a {
            display: block;
            position: relative;
            padding-top: 65%;
            overflow: hidden;
            &:nth-child(n+9){
                display: none;
            }
        }
        img {
            position: absolute;
            display: block;
            min-height: 100%;
            min-width: 100%;
            top:50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 1fr 1fr;
            a {
                &:nth-child(n+9) {
                    display: block;
                }
                &:nth-child(n+10) {
                    display: none;
                }
            } 
        }
    }

    @include media-breakpoint-down(md) {
        > a {
            &:nth-child(9) {
                display: none;
            }
        }
    }

}
