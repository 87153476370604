@import "../abstracts/_settings.scss";

$header-font-family: "Inter", sans-serif;
$body-font-family: "Lato", sans-serif;

// -----------------------------------------------------------------------------
// This file contains all @font-face declarations
// ----------------------------------------------------------------------------
// @font-face {
//     font-family: 'eina';
//     src: url("#{map-get($appPaths, fonts)}/eina-bold-webfont.woff2") format('woff2'),
//          url("#{map-get($appPaths, fonts)}/eina-bold-webfont.woff") format('woff'),
//          url("#{map-get($appPaths, fonts)}/eina-bold-webfont.ttf") format('truetype');
//     font-weight: normal;
//     font-style: normal;
// 	font-display: swap;
// }

// @font-face {
//     font-family: 'maison_neue_bold';
//     src: url("#{map-get($appPaths, fonts)}/MaisonNeueWEB-Bold.woff2") format('woff2'),
//          url("#{map-get($appPaths, fonts)}/MaisonNeueWEB-Bold.woff") format('woff');
//     font-weight: normal;
//     font-style: normal;
// 	font-display: swap;
// }

// @font-face {
//     font-family: 'maison_neue_book';
//     src: url("#{map-get($appPaths, fonts)}/MaisonNeueWEB-Book.woff2") format('woff2'),
//          url("#{map-get($appPaths, fonts)}/MaisonNeueWEB-Book.woff") format('woff');
//     font-weight: normal;
//     font-style: normal;
// 	font-display: swap;
// }

// @font-face {
//     font-family: 'maison_neue_demi';
//     src: url("#{map-get($appPaths, fonts)}/MaisonNeueWEB-Demi.woff2") format('woff2'),
//          url("#{map-get($appPaths, fonts)}/MaisonNeueWEB-Demi.woff") format('woff');
//     font-weight: normal;
//     font-style: normal;
// 	font-display: swap;
// }

// @font-face {
//     font-family: 'maison_neue_light';
//     src: url("#{map-get($appPaths, fonts)}/maisonneue-light-webfont.woff2") format('woff2'),
//          url("#{map-get($appPaths, fonts)}/maisonneue-light-webfont.woff") format('woff'),
//          url("#{map-get($appPaths, fonts)}/maisonneue-light-webfont.ttf") format('truetype');
//     font-weight: normal;
//     font-style: normal;
// 	font-display: swap;
// }

// @font-face {
//     font-family: 'maison_neue_light_italic';
//     src: url("#{map-get($appPaths, fonts)}/maisonneue-lightitalic-webfont.woff2") format('woff2'),
//          url("#{map-get($appPaths, fonts)}/maisonneue-lightitalic-webfont.woff") format('woff'),
//          url("#{map-get($appPaths, fonts)}/maisonneue-lightitalic-webfont.ttf") format('truetype');
//     font-weight: normal;
//     font-style: normal;
// 	font-display: swap;
// }