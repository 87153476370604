.imageFrameBase {
    border: 10px solid map-get($map: $colours, $key: white);
    position: relative;

    img, figcaption {
        position: absolute;
    }
    img {
        top:0;
    }

    figcaption {
        background: map-get($map: $colours, $key: white);
        bottom: -5px;

       padding: 5px 5px 0 0;
    }
}
.image-frame {
    &--16-9 {
        @extend .imageFrameBase;
        padding-top: 56.25%;
    }
    &--4-3 {
        @extend .imageFrameBase;
        padding-top: 75%;
    }
}
// img {
//     max-width: 100%;
//     height: auto;
//     display: inline-block;
// }


.media--16-9 {
    position: relative;
    overflow: hidden;
    padding-top: 56.25% !important;

    > img {
        position: absolute;
        top: 50%;
        left: 50%;
        max-width: 100%;
        min-width: 100%;
        transform: translate(-50%, -50%)
    }
}