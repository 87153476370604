@import "../abstracts/_colours.scss";

.cm_trustedBy {
    text-align: center;

    img {
        max-width: 100%;
    }

    
    .row {
        > div {
            &:nth-child(n+3) {
                margin-top: 30px;
            }

            @include media-breakpoint-up(lg) {
                &:nth-child(n+3) {
                    margin-top: 0;
                }

                &:nth-child(n+5) {
                    margin-top: 30px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            justify-content: center;
            align-items: center;
        }
    }
    
    .spotify,
    .netflix,
    .stdLife,
    .youGov,
    .logitech,
    .stdChartered,
    .pwc {
        position: relative;
        top: 0;
    }

    @include media-breakpoint-up(sm) {
        .netflix {
            top: 8px;
        }

        .stdLife {
            top: -10px;
        }

        .youGov {
            top: 9px;
        }

        .logitech {
            top: 7px;
        }

        .pwc {
            top: 18px;
        }
    }

    @include media-breakpoint-up(md) {
        .youGov {
            top: 12px;
        }
    }

    @include media-breakpoint-up(lg) {
        .spotify, .netflix, .stdLife, .youGov, .logitech, .stdChartered, .pwc {
            position: relative;
            top: 0;
        }

        .spotify {
            top: 5px;
        }

        .netflix {
            top: 4px;
        }

        .stdLife {
            top: -3px;
        }

        .youGov {
            top: 3px;
        }

        .stdChartered {
            top: -1px;
        }

        .pwc {
            top: 28px;
            left: -20px;
        }
    }
}