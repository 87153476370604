@mixin fieldBase() {
    background-color: map-get($map: $colours, $key: white);
    border: 0;
    width: 100%;
    border-radius: 3px;
}
.form-check-custom {
    label {
        display: block;
        font-size: 1.4rem;
    }

    input:checked + .checkmark {
        background-color: map-get($colours,purple) !important;
        border-color: map-get($colours,purple);
    }

    .checkmark {
        top: 5px;
    }

    .checkmark:after {
        width: 12px;
        height: 10px;
        background-size: contain;
        top: 50% !important;
        left: 50% !important;
        transform: translate(-50%,-50%);
        background-image: url('#{map-get($appPaths, "images")}/check.svg')
    }
}

form {
    button{text-decoration: underline;}
    


    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    textarea {
        display: block;
    }

    label {
        font-size: 1.4rem;
        display: block;
    }

    input,
    textarea,
    select {
        @include fieldBase();
        padding: 15px;
    }


    .select {
       
        select {
            color: #000;
        }

        position: relative;
       

        &::after {
            content: '';
            width: 15px;
            height: 10px;
            background: map-get($map: $colours, $key: purple);
            position: absolute;
            z-index: 2;
            clip-path: polygon(100% 0%, 0 0%, 50% 100%);
            right: 15px;
            top: 19px;
        }

        select {
            position: relative;
            z-index: 1;
            outline: none;
            appearance: none;
            margin: 0;
            //padding: 13px 15px;
            cursor: pointer;

            &::-ms-expand {
                display: none;
            }
        }
    }


    span.error,
    label.error {
        color: Red;
        display: block;
        background-color: #ffcece;
        line-height: 35px;
        padding: 0 15px;
        margin-top: 3px;
    }


    &.form--bordered {

        input,
        textarea,
        select {
            border: 1px solid map-get($map: $colours, $key: greyB);
        }
    }
}

.sidebar_form {
    background-color: map-get($map: $colours, $key: purple);
    color: white;

    @include media-breakpoint-up(lg) {
        margin-left: 50px
    }

    h2 {
        color: inherit;
        font-size: 2.1rem;
        line-height: 2.8rem;

        @include media-breakpoint-up(lg) {
            font-size: 3.2rem;
            line-height: 4.2rem;
        }
    }

    form {
        margin-top: 20px;

        >div {
            +div {
                margin-top: 20px
            }

            ;
        }
    }
}