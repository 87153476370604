.split-module {
	display: flex;
	flex-wrap: wrap;
	margin: 20px auto; // Center the module horizontally
	max-width: 1400px;
	padding: 0 1.5rem; // Add padding of 1.5rem on the left and right edges

	&__left {
		flex: 1 1 50%;
		padding: 20px;

		&__title {
			font-size: 4rem;
			margin-bottom: 10px;
			color: #020202;
		}

		&__content {
			margin-bottom: 40px;
			line-height: 1.5;
			max-width: 490px;
			& p {
				font-size: 2rem;
			}
		}

		&__button {
			background-color: #5f34c1;
			color: #fff;
			padding: 5px 20px;
			border: none;
			border-radius: 6px;
			cursor: pointer;
			text-decoration: none;
			display: inline-block;
			font-weight: bold;
			font-size: 1.75rem;
		}
	}

	&__right {
		flex: 1 1 50%;
		display: flex;
		flex-direction: column;

		&__grid {
			display: grid;
			grid-template-columns: 1fr; // Default to single column for mobile
			gap: 20px;

			@media (min-width: 768px) {
				grid-template-columns: 1fr 1fr; // Two columns for tablets and above
			}

			@media (min-width: 1024px) {
				grid-template-columns: repeat(2, 1fr); // 2x2 grid for desktop
			}
		}

		&__block {
			display: flex;
			align-items: flex-start;
			padding: 10px;

			&__image {
				width: 100px;
				height: 100px;
				margin-right: 10px;
				object-fit: cover;
			}

			&__text {
				flex: 1;
				margin-top: 15px;
			}

			&__block-title {
				font-size: 3.2rem;
				margin-bottom: 5px;
				color: #020202;
			}

			&__block-content {
				line-height: 1.3;
			}
		}
	}

	@media (max-width: 768px) {
		&__left,
		&__right {
			flex: 1 1 100%;
		}

		&__grid {
			grid-template-columns: 1fr; // Single column for mobile
		}

		&__block {
			flex-direction: column;

			&__image {
				width: 100%;
				height: auto;
				margin-bottom: 10px;
			}
		}
	}
}
