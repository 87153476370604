/*MAP START*/
.map {
    height: 100%;
    z-index: 1;
	background-color: map-get($colours, purple);
	
    &Marker {
        &__popup {

            a {
                color: map-get($colours, blue);
            }

            a.btn--sm {
                display: block;
                font-size: 1.4rem;
                margin-top: 15px;
            }

            h4 {
                font-size: 1.3rem;
                font-family: "maison_neue_demi";
                margin: 0 0 15px 0;
                padding: 0;
            }

            &Image {
                .splide__pagination {
                    display: none;
                }

                .splide__slide {
                    padding-top: 100%;

                    img {
                        position: absolute;
                        top: 50%;
                        transform: translate(0, -50%);
                        max-height: 100%;
                        max-width: unset;
                    }
                }
            }

            &Content {
                padding: 20px 12px;

                strong {
                    margin: 0 3px;
                }
            }
        }
    }

    &__iconBtn {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translate(-50%, 0);


        @extend .shadow;
        cursor: pointer;
        padding: 0 20px 0 50px;
        //min-width: 128px;
        height: 35px;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $white;
        border: 1px solid map-get($colours, greyB);
        border-radius: 3px;
        color: $black;

        img {
            position: absolute;
            left: 20px;
        }

        &--location {
            img {
                top: 6px;
            }
        }

        &--filter {
            svg {
                width: 18px;
                height: 17px;
                position: absolute;
                left: 20px;
                top: 8px;
            }
        }
    }

    &_marker--pin {

        svg {
            g#Group-2 path {
                &:nth-child(2) {
                    transition: fill 0.15s ease;
                }
            }

            &:hover {
                g#Group-2 path {
                    &:nth-child(2) {
                        fill: map-get($map: $colours, $key: pink);
                    }
                }
            }
        }

        width: 30px;
        height: 40px;
        background-size: contain;

        &.active {
            > svg {
                g#Group-2 path:nth-child(2) {
                    fill: map-get($map: $colours, $key: pink);
                }
            }
        }
    }

    &__controls {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
        pointer-events: none;

        @include media-breakpoint-up(xl) {
            > * {
                pointer-events: all;
            }
        }

        &__groupA {
            position: absolute;
            left: 15px;
            bottom: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        button.close-map {
            position: absolute;
            top: 15px;
            right: 30px;
            background-color: $white;
            border-radius: 5px;
            border: 1px solid map-get($colours, greyB);
            width: 122px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: map-get($colours,grey);

            div, svg {
                position: absolute;
            }

            div {
                top: 9px;
                left: 15px;
            }

            svg {
                right: 10px;
                top: 9px;
            }
        }

        .consultantBanner {
            @media only screen and (max-width: 669px) {
                display: none;
            }

            background: $white;
            border-radius: 5px;
            border: 1px solid map-get($colours, greyB);
            display: inline-flex;
            flex-direction: row;
            align-items: center;
            padding: 5px 20px 5px 5px;
            position: absolute;
            top: 15px;
            left: 15px;
            opacity: 1;
            transition: opacity 0.15s ease;

            p,
            h2 {
                padding: 0;
                margin: 0;
            }

            img {
                display: block;
                width: 65px;
                margin: 5px;
                border-radius: 50%;
                border: 2px solid $white;
            }

            > svg {
                position: absolute;
                top: 7px;
                right: 7px;
                cursor: pointer;
            }

            > div {
                &:nth-child(2) {
                    margin-top: 3px;
                }
            }
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        z-index: 2;
    }
}

.leaflet-container a.leaflet-popup-close-button {
    border-radius: 3px;
    top: 10px;
    right: 11px;
    padding: 0;
    background: map-get($map: $colours, $key: pink);
    width: 25px;
    height: 25px;
    box-shadow: 0 3px 14px rgba(0, 0, 0, 0.15);
    color: $white;
    line-height: 24px;
}

.zoomControls {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: $white;
    width: 36px;
    border-radius: 3px;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    li + li {
        margin-top: 0;
    }

    hr {
        margin: 0;
    }

    .zoomControl {
        height: 39px;
        transition: all 0.15s ease;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        &::selection {
            background-color: transparent;
        }

        &__in {
        }

        &__out {
        }
    }
}

.custom-marker-cluster {
    border-radius: 50%;
    background: map-get($colours,pink);
    display: flex;
    color: $white;
    border: 2px solid $white;
    justify-content: center;
    align-items: center;
    transition: all 0.15s ease;

    &:hover {
        background-color: map-get($map: $colours, $key: "pink");
    }
}

.marker-cluster- {
    &sm {
        box-shadow: 0px 0px 0px 15px rgba($color: map-get($colours,pink), $alpha: .25);

        &:hover {
            box-shadow: 0px 0px 0px 15px rgba($color: map-get($map: $colours, $key: pink), $alpha: .25);
        }
    }

    &md {
        box-shadow: 0px 0px 0px 15px rgba($color: map-get($colours,pink), $alpha: .5);

        &:hover {
            box-shadow: 0px 0px 0px 15px rgba($color: map-get($map: $colours, $key: pink), $alpha: .5);
        }
    }

    &lg {
        box-shadow: 0px 0px 0px 15px rgba($color: map-get($colours,pink), $alpha: .75);

        &:hover {
            box-shadow: 0px 0px 0px 15px rgba($color: map-get($map: $colours, $key: pink), $alpha: .75);
        }
    }
}

.custom-map-icon {
    &.active {
        .map_marker--pin > svg g#Group-2 path:nth-child(2) {
            fill: map-get($map: $colours, $key: pink);
        }
    }
}
/*MAP END*/