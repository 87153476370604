@import "../abstracts/_colours.scss";

@mixin btn-base() {
    cursor: pointer;
    border-radius: 0.3rem;
    border: 0;
    display: inline-block;
    transition: all .15s ease;
    text-align: center;
    text-decoration: none;

    &:disabled {
        opacity: 0.8;
        cursor: unset;
    }
}

.filterButton {
    color: #4c545d;
    border: 1px solid #e2e5e8;
    min-width: 140px;
    background: #fff;
    height: 44px;
    line-height: 44px;
    padding: 0 15px;
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    font-size: 15px;
    white-space: nowrap;
    cursor: pointer;
    justify-content: space-between;
    align-items: center;

    &--active {
        background-color: map-get($map: $colours, $key: purple) !important;
        color: white;

        > svg > path {
            fill: white;
        }
    }
}

.btn {

    &--mob-add-to-favourites {
        width: 37px;
        border: 0;
        background-image: url(/app_themes/images/icons/heart_grey.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-color: transparent;

        &.saved {
            background-image: url(/app_themes/images/icons/heart_red.svg);
        }
    }
    // themes
    &--purple {
        @include btn-base();
        color: $white;
        background-color: map-get($colours,purple);
        border: 1px solid map-get($colours,purple);

        &:not([disabled]) {
            &:hover {
                background-color: darken(map-get($colours, purple), 7%);
            }
        }
    }

    &--pink {
        @include btn-base();
        color: $white;
        background-color: map-get($colours,pink);

        &:not([disabled]) {
            &:hover {
                background-color: lighten(map-get($colours, pink), 5%);
            }
        }
    }

    &--purple-outline {
        @include btn-base();
        color: map-get($colours,purple);
        background-color: $white;
        border: 1px solid map-get($colours,purple);
    }

    &--purple-outlineB {
        @include btn-base();
        color: map-get($colours,blue);
        background-color: map-get($colours, purpleN);
        border: 1px solid map-get($colours,purpleI);

        &:not([disabled]) {
            &:hover {
                background-color: lighten(map-get($colours, purpleN), 1%);
            }
        }
    }

    &--clear {
        cursor: pointer;
        background: transparent;
        border: 0;
        color: map-get($map: $colours, $key: purple);

        &:not([disabled]) {
            &:hover {
                text-decoration: underline;
            }
        }
    }
    //size modifiers
    &--sm {
        font-size: 1.6rem;
        line-height: 3.7rem;
    }

    &--md {
        line-height: 4.6rem;
        padding: 0 15px;
    }

    &--lg {
        line-height: 4.9rem;
    }

    &--xl {
        font-size: 1.8rem;
        line-height: 5.3rem;
        padding: 0 35px;
    }
    // responsive button sizes
    &--r {
        //sm
        font-size: 1.6rem;
        line-height: 3.7rem;
        //lg
        @include media-breakpoint-up(lg) {
            line-height: 4.9rem;
        }
    }

    &--rx {
        //sm
        font-size: 1.6rem;
        line-height: 3.7rem;
        //xl
        @include media-breakpoint-up(lg) {
            font-size: 1.8rem;
            line-height: 5.3rem;
        }
    }
    // unique buttons
    &--burger {
        border: 0;
        background: transparent;
        padding: 0;

        span {
            display: block;
            background-color: map-get($map: $colours, $key: grey);
            height: 3px;
            width: 27px;
            border-radius: 2px;
			padding: 0;

            + span {
                margin-top: 5px;
            }
        }
    }

    &--play {

        @extend .btn--pink;
        border: 0;
        border-radius: 50%;
        width: 45px;
        height: 45px;
        position: relative;

        &::after,
        &::before {
            position: absolute;
            content: '';
        }

        &::before {
            top: 13px;
            left: 18px;
            width: 0;
            height: 0;
            border-top: 9px solid transparent;
            border-bottom: 9px solid transparent;
            border-left: 13px solid map-get($colours, white);
        }


        &.playing {
            &:before {
                left: 7px;
            }

            &::before, &::after {
                top: 6px;
                transform: none;
                height: 45%;
                width: 4px;
                border: 0;
                background-color: white;
            }

            &::after {
                right: 7px;
            }
        }

        @include media-breakpoint-up(md) {
            width: 55px;
            height: 55px;

            &::before {
                top: 15px;
                left: 21px;
                border-top-width: 12px;
                border-bottom-width: 12px;
                border-left-width: 16px;
            }
        }

        @include media-breakpoint-up(lg) {
            width: 90px;
            height: 90px;

            &::before {
                top: 24px;
                left: 37px;
                border-top-width: 20px;
                border-bottom-width: 20px;
                border-left-width: 24px;
            }
        }
    }

    &--videoCardPlay {
        display: block; // as its a span in this context
        width: 50px;
        height: 50px;
        background: rgb(94,74,159);
        background: linear-gradient(132deg, rgba(94,74,159,1) 0%, rgba(111,89,180,1) 100%);
        border-radius: 50%;
        position: relative;
        align-items: center;
        justify-content: center;
        border: 2px solid map-get($map: $colours, $key: white);

        &::after {
            top: 50%;
            left: 25px;
            transform: translate(-50%,-50%);
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 14px solid map-get($map: $colours, $key: pinkD);
        }

        @include media-breakpoint-up(xl) {
            width: 60px;
            height: 60px;

            &::after {
                left: 30px;
                border-top: 12px solid transparent;
                border-bottom: 12px solid transparent;
                border-left: 16px solid map-get($map: $colours, $key: pinkD);
            }
        }
    }

    &--dropdown {
        color: map-get($map: $colours, $key: grey);
        font-size: 1.5rem;
        background-color: map-get($map: $colours, $key: white);
        border-radius: 100px;
        line-height: 20px;
        border: 1px solid map-get($map: $colours, $key: greyB);
        padding: 0 50px 0 20px;
        background-image: url('#{map-get($appPaths, "images")}/icons/purple-down-arrow.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) 50%;
		padding-right: 12rem !important;

        &[aria-expanded="true"] {
            background-image: url('#{map-get($appPaths, "images")}/icons/purple-up-arrow.svg');
        }
    }

    &--saveA {
        line-height: 40px;
        border: 0;
        padding: 0 50px 0 20px;
        border-radius: 100px;
        background-image: url('#{map-get($appPaths, "images")}/icons/heart_outline.svg');
        background-repeat: no-repeat;
        background-position: 80% 50%;
        background-color: map-get($map: $colours, $key: white);
        border: 1px solid map-get($map: $colours, $key: greyB);

        &::before {
            content: 'Save';
        }

        &.saved {
            background-image: url('#{map-get($appPaths, "images")}/icons/heart_red.svg');

            &::before {
                content: 'Saved';
            }
        }
    }

    &--shareA {
        line-height: 40px;
        border: 0;
        padding: 0 50px 0 20px;
        border-radius: 100px;
        background-image: url('#{map-get($appPaths, "images")}/icons/heart_outline.svg');
        background-repeat: no-repeat;
        background-position: 80% 46%;
        background-color: map-get($map: $colours, $key: white);
        border: 1px solid map-get($map: $colours, $key: greyB);
        background-size: 23px;
        background-image: url('#{map-get($appPaths, "images")}/icons/share.svg');
        transition: background-color 0.15s linear;

        &:hover {
            background-color: map-get($colours, greyB);
            
        }
    }

    &--saveB {
        cursor: pointer;
        opacity: 1;
        width: 23px;
        height: 21px;
        border: 0;
        background: transparent;
        background-image: url('#{map-get($appPaths, "images")}/icons/heart_grey.svg');
        background-repeat: no-repeat;
        background-size: contain;
        padding: 0;
        transition: opacity 0.15s ease;

        &.saved {
            opacity: 1;
            background-image: url('#{map-get($appPaths, "images")}/icons/heart_red.svg');
        }
    }

    &--closeMobNav {
        width: 21px;
        height: 21px;
        border: 0;
        background-color: transparent;
        background-image: url('#{map-get($appPaths, "images")}/icons/close-mob-nav.svg');
        background-repeat: no-repeat;
    }
}

.sending {
    background-color: #765fc2;
    
    &::after {
        content: ''
    }
}

.sent {
    background-color: #78bd62;
}