/* Cookie bar styles */
.cookie_bar {
	padding: 0 15px;
	width: 100%;
	position: fixed;
	bottom: 0;
	display: none;
	z-index: 1100;

	@include MQ(991px) {
		width: 380px;
		bottom: 15px;
		left: 15px;
		border: 1px solid $white;
		border-radius: 10px;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
	}
}
.cookie_bar_default_theme {
	color: map-get($colours, grey);
	background-color: $white;
}
.cookie_bar_container {
	display: flex;
    flex-direction: column;
    flex-wrap: wrap;
	justify-content: space-evenly;
	max-width: 600px;
	margin: 0 auto;

	@include MQ(991px) {
		margin: 10px auto 20px auto;
	}

	& p {
		font-size: 1.3rem;
    	line-height: 1.9rem;
	}

	& label, button {
		font-size: 1.3rem;
    	line-height: 1.9rem;
	}
}
.cookie_bar_policy_container {

	padding: 0 0 0.5rem 0;

	& a {
		color: map-get($map: $colours, $key: purple);
		margin: 5px 0;
	}
}
.cookie_bar_buttons {
	padding: 0.5rem 0 0 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-evenly;

	@include MQ(450px) {
		justify-content: flex-end;
	}

	@include MQ(450px) {
		justify-content: space-evenly;
	}

	@include MQ(991px) {
		padding: 0.5rem 0 0.8em 0;
	}
}
.cookie_bar_buttons button {
	background: map-get($colours, greenF);
    color: $white;
    border: 1px;
    padding: 1rem 4rem;
	float: left;
	margin: 0;
	//width: 125px;
}

.cookie_bar_buttons button:first-child {
	margin-right: 10px;
}

.cookie_bar h3 {
	// text-decoration: underline;
	@include MQ(991px) {
		margin-top: 0.5rem;
		margin-bottom: 0;
	}
}
.cookie_bar p {
	margin: 5px 0;
}
.cookie_bar_content {
	padding: 20px 10px;
    border: 1px solid map-get($colours, greyB);
    border-radius: 10px;
	max-width: 100%;

	@include MQ(420px) {
		padding: 20px 30px;
	}

	@include MQ(600px) {
		padding: 20px 10px;
	}

	@include MQ(991px) {
		padding: 0px 10px;
	}
}
.cookie_bar_content button {
	width: 100%;
	background-color: $white;
	border: none;
	border-radius: 5px;
}
.cookie_policy_dropdown {
	height: 0;
	background: transparent;
	//TO UNHIDE IN FUTURE, REMOVE DISPLAY NONE
	display: none;
}
.cookie_policy_dropdown p {
	margin: 1rem 0;
    padding: 0;

	@include MQ(991px) {
		margin: unset;
	}

}
.cookie_policy_dropdown ul {
	margin: 0;
	padding: 1rem 2.5rem;
	height: 100%;
	overflow-y: auto;
	display: none;
}
.cookie_policy_dropdown ul li {
	margin-bottom: 1rem;
}
.cookie_policy_dropdown p::after {
	content: 'Open';
	text-decoration: underline;
	font-weight: bold;
    text-align: right;
	float:right;
	// margin-right: 10px;
	//FOR CHEVRON UNCOMMENT
	// border-style: solid;
    // border-width: 3px 3px 0px 0px;
    // content: '';
    // height: 18px;
    // left: 0.15em;
    // position: relative;
    // top: 0.15em;
    // transform: rotate(-45deg);
    // vertical-align: top;
    // width: 18px;
    // float: right;
    // margin: 5px;
}
.cookie_policy_dropdown p.bottom::after {
	content: 'Close';
	//FOR CHEVRON UNCOMMENT
	// top: -0.85em;
	// transform: rotate(135deg);
}
.disabled-checkbox-wrapper {
	padding-bottom: 10px;
    width: 50%;

	@include MQ(600px) {
		width: 25%;
		padding-bottom: unset;
	}

	@include MQ(991px) {
		width: 100%;
	}
}
.checkbox_container {
    width: 50%;

	@include MQ(600px) {
		width: 25%;
	}

	@include MQ(991px) {
		width: 100%;
	}
}
.checkbox_container p{
	position: relative;
    width: 80px;
    margin: 0;
}
#cookie_bar_toggle {
	color: map-get($colours, grey);
	padding: 0 10px;
	width: auto;
	position: absolute;
	bottom: -35px;
	left: 5%;
	cursor: pointer;
	border-radius: 5px;
	background-color: $white;
	transition: bottom 0.5s;
	z-index: 1;
	display: none !important;

	@include MQ(768px) {
		display: block;
	}
}
#cookie_bar_toggle p {
	margin: 0;
	padding: 10px 10px 10px 10px;
}
#cookie_bar_toggle:hover {
	bottom: -5px;
}

#cookie_bar_toggle_mobile {
	display: block;
	height: 30px;

	@include MQ(768px) {
		display: none;
	}

	& p {
		color: $white;
		padding: 1.5rem 0;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// justify-content: space-evenly;
	}

}


/*Check box CSS - Required*/
/* Customize the label (the container) */
.disabled-checkbox-wrapper label {
    width: 100%;
}

.checkbox_outer {
	padding: 0;
	margin: 0 auto;
	max-width: 350px;
	display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

	@include MQ(600px) {
		max-width: 100%;
	}
}

.checkbox_outer label {
	display: flex;
    flex-direction: row;
    justify-content: space-between;
	color: map-get($colours, grey);
	padding: 0px 10px 5px 0px;

	@include MQ(360px) {
		padding: 0px 20px 5px 0px;
	}

	@include MQ(991px) {
		padding: 10px;
		border-bottom: 1px solid map-get($colours, greyB);
	}
}


  .main_Content button {
	background: map-get($colours,orangeB);
    color: $black;
    border: 1px;
    padding: 8px 40px;
	height: 40px;
  }

  .cookie_baar .center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
  }
  
  input[type="checkbox"] {
	margin-left: 5px;
	top: 3px;
	position: relative;
	width: 35px;
	height: 15px;
	-webkit-appearance: none;
	background: map-get($colours, greyB);
	outline: none!important;
	cursor: pointer;
	border-radius: 20px;
	box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
	transition: background 300ms linear;
  }
  
  input:checked[type="checkbox"] {
	background: map-get($colours, greenF);
  }
  
  input[type="checkbox"]::before {
	content: "";
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 20px;
	top: 0;
	left: 0;
	background: $white;
	transform: scale(1.1);
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
	transition: left 300ms linear;
  }

  input[type="checkbox"]::after {
		top: 50%;
		right: 8px;
		width: 12px;
		height: 12px;
		transform: translateY(-50%);
		background: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/uncheck-switcher.svg);
		background-size: 12px 12px;
  }
  
  input:checked[type="checkbox"]::before {
	left: 20px;
  }
  