@import "../abstracts/_colours.scss";
@import "../abstracts/_settings.scss";

body.navOpen {
    
    overflow: hidden;

    #mobileNav {
        display: flex;
    }
}

#mobileNav {
    display: none;
    font-size: 1.5rem;
    z-index: 3000;
    bottom: 0;
    top: 60px;
    overflow: scroll;

    a {
        text-decoration: none;
    }

    footer {
        a {
            @extend .txt--purple;
            font-weight: bold;
        }
    }

    @media only screen and (min-width: 768px) {
        top: 80px;
    }
}