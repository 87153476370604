.cm_howItWorks {

    img {
        height: 90px;
    }

    .row {
        @include media-breakpoint-down(md) {
            
            > div {
           
                + div {
           
                    &:before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 15px;
                        right: 15px;
                        height: 1px;
                        background-color: map-get($map: $colours, $key: greyB);
                    }
                }
            }
        }

        @include media-breakpoint-up(lg) {
            position: relative;

            > div {
                $i: -15px;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    width: 0;
                    height: 0;
                    border-width: 30px 0 30px 33px;
                    border-color: transparent transparent transparent rgba(243,126,213,0.75);
                    border-style: solid;
                    right: $i;
                }

                &:after {
                    right: $i - 18px;
                }

                &:last-child {
                    &:before, &:after {
                        display: none;
                    }
                }
            }
        }
    }
}