@import "../abstracts/_colours.scss";

.cm_centralImageBanner {
    
    figure {
        padding-top: 77.669902912621359223300970873786%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
    }
    
    a {
        @include media-breakpoint-up(lg) {
            width: unset !important;
            font-size: 1.8rem;
            line-height: 5.3rem;
        }
    }
    
    @include media-breakpoint-down(md){
        > div {box-shadow: none;}
    }

    @include media-breakpoint-up(lg) {
        
        max-height: 895px;
        max-width: 1440px;

        > div {
            position: absolute;
            width: 750px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 1.7rem;
        }
    }
}