// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

// accepts solid and rgba values for $colour
@mixin Dropshadow($colour: #000, $blur:5px, $spread: 5px, $inset: false, $shiftRight: 0px, $shiftDown: 0px) {

	@if $inset { 
    -webkit-box-shadow: inset $shiftRight $shiftDown $blur $spread $colour; 
    box-shadow: inset $shiftRight $shiftDown $blur $spread $colour;
  } @else {
    -webkit-box-shadow: $shiftRight $shiftDown $blur $spread $colour; 
    box-shadow: $shiftRight $shiftDown $blur $spread $colour;
  }

}

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

//media queries
@mixin MQ($screen: false, $media: 'screen') {
	@if $screen != false {
		@media #{$media} and (min-width: $screen) {
			@content
		}
	}  
}