@import "../abstracts/_colours.scss";

.cm_numberTicker {

    .row {
        @include media-breakpoint-down(sm) {
            > div {
                &:nth-child(n+3) {
                    margin-top: 30px;
                }
            }
        }

        @include media-breakpoint-up(md) {
            > div {
                &:nth-child(n+5) {
                    margin-top: 30px;
                }
            }
        }
    }

    .of-stats {
        min-height: 123px;
        border-radius: 14px;
        border: 1px solid map-get($map: $colours, $key: purpleH);
        background-image: linear-gradient(180deg,#FDFCFF 0%,#F9F8FF 100%);

        @include media-breakpoint-down(md) {
            .leader {
                line-height: 2rem;
            }
        }

        @include media-breakpoint-up(lg) {
            min-height: 165px;
            border-radius: 30px;

            .leader {
                line-height: 2.5rem;
            }
        }

        @include media-breakpoint-up(lg) {
            min-height: 220px;
        }

        @include media-breakpoint-up(xl) {
            min-height: 263px;
        }
    }
}
