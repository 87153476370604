.cm_officeFreedomAdvantage{    
    img {
        max-height: 60px;
        @include media-breakpoint-up(lg) {
            max-height: 70px;
        }
    }
    span {
        line-height: 2.4rem;
    }
}