notific8-container {
    z-index: 1032;
}

///Theme(s)

.themeBase {
    $cbDimensions: 25px;
    
    overflow: visible;;
    border-width:  1px;
    border-style: solid;
    padding: 15px;
    border-radius: 3px;
    margin-right: 10px;
    position: relative;
    box-shadow: 0 0 15px 0 rgba(168, 168, 168, 50%);
    a {
        font-weight: bold;
    }
    &::before {   
        $iconDimensions: 30px;
        top: 50%;
        transform: translate(0,-50%);
        content: '';
        position: absolute;
        left: 15px;
        width: $iconDimensions;
    }

    .notific8-close-button {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translate(0,-50%);
        width: $cbDimensions;
        height: $cbDimensions;
        line-height: $cbDimensions;
        border-radius: 50%;
        text-align: center;
    }

    .notific8-content {
        padding-left: 45px;
        transition: all 0.15s ease;
    }

    &:hover {
        .notific8-content {
            padding-right: 30px;
        }
    }

}

.notific8 {

    &__unloved {
        @extend .themeBase;

        &::before {
            background-image: url('#{map-get($appPaths, "images")}/icons/broken-heart.svg');
            background-size: cover;
            background-repeat: no-repeat;
            height: 25px;
        }

        background-color: map-get($colours, greyB);
        border-color: map-get($colours, purpleF);

        .notific8-close-button {
            background: map-get($colours, purpleF);
        }
    }

    &__loved {
        @extend .themeBase;

        &::before {
            background-image: url('#{map-get($appPaths, "images")}/icons/heart.svg');
            background-size: contain;
            background-repeat: no-repeat;
            height: 29px;
        }

        background-color: map-get($colours,purple);
        border-color: map-get($colours,purple);
        color: $white;

        a {
            color: inherit;
        }

        .notific8-close-button {
            background: darken(map-get($colours,purple), 10%);
        }
    }


    &__copied {
        @extend .themeBase;

        &::before {
            background-image: url('#{map-get($appPaths, "images")}/icons/link.svg');
            background-size: contain;
            background-repeat: no-repeat;
            height: 29px;
        }

        background-color: map-get($colours,purple);
        border-color: map-get($colours,purple);
        color: $white;

        a {
            color: inherit;
        }

        .notific8-close-button {
            background: darken(map-get($colours,purple), 10%);
        }
    }
}