.mobile-filters-menu {
    width: 365px;
    visibility: hidden;
    transition: all .2s;
    border-radius: 0;
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    top: 0;
    right: auto;
    left: 0;
    z-index: 1070;
    overflow-y: auto;
    overflow-x: hidden;
    transform: translateX(-100%);
    -webkit-overflow-scrolling: touch;
    padding-bottom: 150px;

    &.active {
        visibility: visible;
        transform: translateX(0);
        transition: transform .2s;
    }    

    .inside-filters-menu {
        overflow-y: auto;
        overflow-x: hidden;
        z-index: -1;
        scroll-behavior: smooth;
    }

    .form-check input:checked ~ .checkmark {
        background-color: map-get($colours,purple);
        border-color: map-get($colours,purple)
    }

    .view-offices-btn {
        border-top: 1px solid $border-color;
        bottom: 0;
    }

    .price-range {
        &__pricesWrapper {
            margin-right: -15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 1.4rem;
            font-weight: 700;
        }
    }

    .all-include {
        > div {
            &:before {
                content: '•';
                font-size: 2;
                width: 12px;
                height: 10px;
                position: absolute;
                left: 0px;
                top: 0;
            }
        }
    }

    #mclose {
        width: 20px;
        height: 20px;

        span {
            height: 3px;
            border-radius: 1px;
            opacity: 1;
            left: 0;
            transform: rotate(0deg);
        }

        span:nth-child(1) {
            transform: rotate(45deg);
        }

        span:nth-child(2) {
            transform: rotate(-45deg);
        }
    }
    
    @include media-breakpoint-down(xs) {
        width: 100%;
    }

}
