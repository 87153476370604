
.card {
    border-radius: 5px;
    background-color: map-get($map: $colours, $key: white);
    border: 1px solid map-get($map: $colours, $key: greyG);
    overflow: hidden;

    figure {
        padding: 0;
        margin: 0;
    }

    &__content {
        padding: 20px;
        font-size: 1.5rem;
        line-height: 2.4rem;

        @include media-breakpoint-up(lg) {
            padding: 40px;
        }
    }

    &__tpDetails {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
    }

    header {
        margin-bottom: 10px;
    }

    p {
        margin-top: 0;
    }

    &--popular {
        &:hover {
            > a img {
                transform: translate(-50%,-50%) scale(1.1);
            }
        }

        > a {
            padding-top: 56.25%;
            overflow: hidden;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transition: transform 0.15s ease;
                transform: translate(-50%,-50%);
            }
        }
    }

    &--trustPilot {
        h4 {
            font-size: 1.4rem;
            line-height: 2.1rem;
            
			font-family: $body-font-family;
			font-weight: 700;
            letter-spacing: 0.3px;
        }

        p {
            font-size: 1.4rem;
            line-height: 2.4rem;
            margin-bottom: 0;
        }
    }

    &--teamMember {
        // box-shadow: none;
        // border: 0;
        margin-bottom: 30px;

        header {
            margin: 0;
        }

		h2 {
            font-size: 1.6rem;
			font-family: $body-font-family;
			font-weight: 700;
            letter-spacing: 0.3px;
        }

        h3 {
            font-size: 1.6rem;
			font-family: $body-font-family;
			font-weight: 700;
            letter-spacing: 0.3px;
        }

        ul {
            margin: 0;
            @extend .list-unstyled;
        }

        .card__content {
            //padding: 15px 0;
            padding: 15px;
        }

        figure {
            padding: 0;
            position: relative;

            a {
                position: absolute;
                width: 40px;
                height: 40px;
                background-color: map-get($map: $colours, $key: white);
                bottom: 0;
                left: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                &::before {
                    content: '';
                    position: absolute;
                    width: 23px;
                    height: 23px;
                    background-image: url('#{map-get($appPaths, "images")}/icons/linkedin.svg');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }
    }

    &--video-podcast {
        margin-bottom: 30px;

        p {
            margin-bottom: 0;
        }

        h3 {
            font-size: 2rem;
            line-height: 2.5rem;
        }

        .card__content {
            padding: 30px 35px;
        }

        .pill {
            position: absolute;
            z-index: 2;
            top: 10px;
            left: 10px;

            @include media-breakpoint-up(xl) {
                top: 15px;
                left: 15px;
            }
        }

        figure {
            padding-top: 75%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                max-height: 100%;
                min-width: 100%;
                max-width: unset;
                left: 50%;
                transform: translate(-50%,-50%);
            }

            &::after {
                z-index: 1;
                left: 0;
                content: '';
                position: absolute;
                width: 110%;
                height: 50px;
                background: white;
                bottom: -50px;
                transform: rotate(-4deg);
                transform-origin: top left;

                @include media-breakpoint-up(md) {
                    transform: rotate(-6deg);
                }
            }
        }

        .btn--videoCardPlay {
            position: absolute;
            z-index: 2;
            bottom: 0;
            right: 5px;

            @include media-breakpoint-up(xl) {
                right: 10px;
            }
        }
    }

    &--business-service {
        margin-bottom: 30px;
        border-radius: 0;

        ul {
            margin-top: 0;
            padding: 0 0 0 17px;
        }

        h3 {
            
			font-family: $body-font-family;
			font-weight: 400;
            font-size: 1.6rem;
            letter-spacing: 0.3px;
        }

        .card__content {

            a {
                border-radius: 0;
            }
        }

        figure {
            padding-top: 56.25%;
            position: relative;
            overflow: hidden;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                max-width: 100%;
            }
        }
    }

    &--business-center {
        cursor: pointer;
        margin-bottom: 15px;
        box-shadow: none;
        /*&.fave {
            background-image: url(/App_Themes/images/love.png);
            background-repeat: no-repeat;
            background-position: 150% 100%;
            background-size: 65%;

            @include media-breakpoint-up(xl) {
                background-position: 0 100%;
                background-size: 60%;
            }
        }*/

        a {
            text-decoration: none;
        }

        small {
            font-size: 1.3rem;
            
			font-family: $body-font-family;
			font-weight: 400;
        }

        .card__content {
            font-size: 1.3rem;
            line-height: 1.4rem;
            padding: 15px;
            position: relative;
            // QUICK HACK TO SPEED UP DEVELOPMENT, remove and utilify when you can
            .icons {
                align-items: flex-end;
                position: absolute;
                right: -20px;
                top: 0;

                @media only screen and (min-width: 1300px) {
                    right: 0;
                }

                > * {
                    transition: transform 0.05s linear;

                    &:hover {
                        transform: scale(1.1);
                    }
                }

                .js-show-on-map {
                    width: 30px;
                    height: 37px;
                    background-image: url("/App_Themes/images/icons/map-marker.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    border: 0;
                    padding: 0;
                    margin: 5px 0 0 0;
                    background-color: transparent;
                    background-position: center;
                }

                .btn--mob-add-to-favourites {
                    width: 30px;
                    height: 30px;
                }
            }

            button.btn--saveB {
                position: absolute;
                z-index: 10;
                right: 0;

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            header {
                margin-bottom: 5px;
            }

            h2 {
                margin-bottom: 5px;
                font-size: 1.7rem;
                line-height: 2.5rem;
                
				font-family: $body-font-family;
				font-weight: 700;
                letter-spacing: 0.3px
            }

            ul.facilities {
                @extend .list-unstyled;
                margin: 10px 0 10px 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                li {
                    line-height: 2rem;
                    margin: 0 5px 0 0;

                    + li {
                        position: relative;

                        &::before {
                            content: '•';
                            position: absolute;
                            left: 0;
                        }

                        padding: 0 0 0 10px;
                    }
                }
            }

            ul.stations {
                @extend .list-unstyled;
                margin: 0 0 10px 0;

                svg {
                    margin-right: 10px;
                    width: 15px;
                    margin-top: 4px;
                }

                li {
                    display: flex;

                    + li {
                        margin: 0;
                    }
                }
            }

            .pill--occupancy {
                margin-bottom: 20px;
            }

            .btn-wrap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                @include media-breakpoint-up(xl) {
                    justify-content: flex-end;
                }
            }

            a[class^=btn--] {
                @extend .btn--sm;
                font-size: inherit;
                max-height: 35px;
                line-height: 35px;
                width: 48%;
            }


            .price {
                padding-top: 15px;
                display: block;
                border-top: 1px solid map-get($map: $colours, $key: greyB);
                margin-bottom: 15px;
                color: map-get($map: $colours, $key: blue);

                strong {
                    font-size: 2rem;

                    @include media-breakpoint-up(xl) {
                        font-size: 2.4rem;
                    }
                }

                .please-call {
                    strong {
                        font-size: 1.6rem;
                    }
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }

        @include media-breakpoint-up(xl) {
            display: flex;
            flex-direction: row;
            padding: 30px 0 30px 0;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            //border-radius: 0;
            position: relative;
            z-index: 1;
            transition: all 0.15s linear;



            .card__content {
                display: flex;
                //display: none;
                flex-direction: column;
                padding: 0 0 0 30px;
                grid-column-start: 2;
                grid-column-end: 4;
                align-self: flex-end;

                a[class^=btn--] {
                    width: 130px;
                }

                ul.stations {
                    margin-bottom: 15px;
                }

                h3 {
                    padding-right: 40px;
                    font-size: 2.1rem;
                }

                .price {
                    //margin-top: -44px;
                    text-align: right;
                    border-top: 0;
                    margin-bottom: 10px;
                    line-height: 1.8rem;
                    padding-top: 0;
                }

                .btn-wrap {
                    // width: 280px;
                    margin-left: auto;
                }

                flex: 0 0 50%;
            }
        }

        .carousel {

            @media only screen and (max-width: 1199px) {
                .splide__slide {
                    padding-top: 56.25%;
                    position: relative;
                }

                figure {
                    position: absolute;
                    top: 0; 
                    left: 0;
                    right: 0; 
                    bottom: 0;
                    a{
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                    img {
                        position: absolute;
                        width: 100%;
                        max-width: 100%;
                       /* height: 100%;
                        max-height: 100%;*/
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }


            @media only screen and (min-width: 1200px) {
                overflow: hidden;
                border-radius: 3px;
                /*width: 430px;*/
                height: 280px;
                flex: 0 0 50%;

                .splide__track {
                    height: 100%;
                }

                .splide__arrows {
                    width: 100%;
                    bottom: 40px;
                    position: absolute;
                }

                .splide__arrows > button {
                    opacity: 0;
                    transition: opacity 0.2s ease;
                }

                .splide__slide {

                    figure {
                        display: block;
                        height: 100%;
                        position: relative;

                        a {
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;

                            img {
                                position: absolute;
                                max-height: 100%;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                    }
                }

                .splide__arrows {
                    width: 100%;
                    bottom: 40px;
                    position: absolute;
                }

                .splide__arrows > button {
                    opacity: 0;
                    transition: opacity 0.2s ease;
                }
            }
        }
        // hover states
        &:hover {
            @include media-breakpoint-up(xl) {
                //transform: scale(1.02);
                margin-left: 20px;
                z-index: 2;
                box-shadow: 42px 0px 24px -12px rgba(0, 0, 0, 0.5);
                -webkit-box-shadow: 42px 0px 24px -12px rgba(0, 0, 0, 0.5);

                .splide__arrows {
                    > button:not([disabled]) {
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    &--consultant {

        h4 {
            
			font-family: $body-font-family;
			font-weight: 700;
            letter-spacing: 0.3px;
            font-size: 1.7rem;
        }

        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;

        header {
            margin: 0;
        }

        img {
            @extend .shadow;
            @extend .d-block;
            border: 2px solid white;
            border-radius: 50px;
            max-width: 55px;
            margin-right: 10px;
        }

        figure {
            margin: 0;
            padding: 0;
        }

        .card__content {
            padding: 0;
            display: flex;
            flex-direction: column;
            font-size: 1.3rem;

			& header p {
				font-weight: bold;
			}

            p {
                font-weight: 400;
                color: map-get($colours, blueCro);
                margin: 0;

                a {
                    color: inherit;
                    text-decoration: underline;
                }
            }
        }

        button {
            $h: 12px;
            position: absolute;
            right: 10px;
            top: 10px;
            background-size: 100% 100%;
            width: $h;
            height: $h;
            opacity: 0.8;
            z-index: 10;
        }
    }

    &--sm-bc {
        border-radius: 0;
        box-shadow: none;
        border: 0;

        figure {
            padding-top: 65%;
            position: relative;
            overflow: hidden;
            border-radius: 3px;
        }

        img {
            position: absolute;
            top: 50%;
            min-height: 100%;
            transform: translate(0,-50%);
        }

        .card__content {
            position: relative;
            font-size: 1.3rem;
            line-height: 1.8rem;
            margin-top: 15px;
            padding: 0 45px 0 0;

            button.btn--saveB {
                position: absolute;
                right: 0;
                top: 0px;
            }

            h3 {
                font-size: inherit;
                line-height: inherit;
                font-family: inherit;
            }
        }
    }

    &--business-center-panel {
        margin-bottom: 15px;
        box-shadow: none;


        @include media-breakpoint-up(md) {
            margin-bottom: 30px;
        }

        small {
            font-size: inherit;
            
			font-family: $body-font-family;
			font-weight: 400;
        }

        figure {
            display: block;
            overflow: hidden;
            padding-top: 56.25%;
            position: relative;

            img {
                position: absolute;
                top: 50%;
                transform: translate(0,-50%);
            }
        }

        .card__content {
            font-size: 1.3rem;
            line-height: 1.4rem;
            padding: 15px;
            position: relative;

            p {
                line-height: 2.2rem;
                margin: 20px 0;
            }

            button.btn--saveB {
                position: absolute;
                z-index: 10;
                right: 0;

                @include media-breakpoint-down(lg) {
                    display: none;
                }
            }

            header {
                margin-bottom: 5px;
            }

            h3 {
                margin-bottom: 5px;
                font-size: 1.7rem;
                line-height: 2.5rem;
                
				font-family: $body-font-family;
				font-weight: 700;
                letter-spacing: 0.3px
            }

            ul.facilities {
                @extend .list-unstyled;
                
				font-family: $body-font-family;
				font-weight: 700;
                margin: 10px 0 10px 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                li {
                    line-height: 2rem;
                    margin: 0 5px 0 0;

                    + li {
                        position: relative;

                        &::before {
                            content: '•';
                            position: absolute;
                            left: 0;
                        }

                        padding: 0 0 0 10px;
                    }
                }
            }

            ul.stations {
                @extend .list-unstyled;
                margin: 0 0 10px 0;

                svg {
                    margin-right: 10px;
                    width: 15px;
                }

                li {
                    display: flex;

                    + li {
                        margin: 0;
                    }
                }
            }

            .pill--occupancy {
                margin-bottom: 20px;
            }

            .btn-wrap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            a[class^=btn--] {
                @extend .btn--sm;
                width: 47%;
                font-size: inherit;
            }

            .price {
                padding-top: 15px;
                display: block;
                border-top: 1px solid map-get($map: $colours, $key: greyB);
                margin-bottom: 15px;
                color: map-get($map: $colours, $key: blue);
            }
        }
    }

    &--client {
        position: relative;

        .card-header {
            height: 212px;
            padding: 0;
            display: flex;
            place-content: center;

            img {
                max-width: 70%;
            }


            &.au-standard-life {
                background-image: linear-gradient(180deg, map-get($colours, blue) 0%, map-get($colours, blue) 100%);

                img {
                    margin-bottom: 13px;
                }
            }

            &.au-amido {
                background-image: linear-gradient(180deg, map-get($colours,orangeB) 0%, map-get($colours,orangeB) 100%);
            }

            &.au-lhh {
                img {
                    width: 30%;
                }

                background-image: linear-gradient(180deg, map-get($colours,orangeC) 0%, map-get($colours,orange) 100%);
            }

            &.au-nhs {
                background-image: linear-gradient(180deg, map-get($colours, blue) 0%, map-get($colours, blue) 100%);
            }

            &.au-groupon {
                background-image: linear-gradient(180deg, map-get($colours, greenD) 0%, map-get($colours, greenE) 100%);
            }

            &.au-hitachi {
                background-image: linear-gradient(180deg, map-get($colours, red) 0%, map-get($colours, red) 100%);
            }
        }
    }

    &--blogPost {
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid map-get($colours, greyB);
        padding-bottom: 40px;






        a {
            -webkit-tap-highlight-color: transparent;
            display: flex;
            flex-direction: column;
            text-decoration: none;
        }

        .card__content {
            padding: 0;
        }

        &__image {
            border-radius: 10px;
            padding-top: 67.85%;
            background: #8462ef;
            margin-bottom: 20px;
            position: relative;
            overflow: hidden;

            > img {
                transition: filter 0.15s ease;
                top: 0;
                left: 50%;
                transform: translate(-50%,0);
                position: absolute;
                max-height: 100%;
                min-height: 100%;
            }
        }

        .blog__articleDetails {
            flex-wrap: wrap;
            margin-bottom: 20px;
        }

        @include media-breakpoint-down(sm) {
            &-md, &-sm {
                margin-top: 50px;
            }
        }

        @include media-breakpoint-up(md) {
            &-lg, &-sm {
                .card--blogPost__image {
                    padding-top: 0;
                }
            }

            &-lg {
                margin-bottom: 50px;

                a {
                    flex-direction: row;
                }

                .card--blogPost__image {
                    flex: 0 0 330px;
                    height: 224px;
                    margin: 0;
                }

                .card__content {
                    margin-left: 30px;
                }
            }

            &-md {
                height: 100%;
            }

            &-sm {
                margin-top: 50px;

                a {
                    flex-direction: row;
                }

                .card--blogPost__image {
                    flex: 0 0 130px;
                    width: 130px;
                    height: 130px;
                    margin: 0;
                }

                .card__content {
                    margin-left: 30px;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &-lg {
                a {
                    align-items: center;
                }

                .card--blogPost__image {
                    flex: 0 0 450px;
                    height: 305px;
                }
            }

            &-sm {
                a {
                    align-items: center;
                }

                .card--blogPost__image {
                    flex: 0 0 220px;
                    width: 220px;
                    height: 220px;
                    margin: 0;
                }
            }
        }

        @include media-breakpoint-up(xl) {
            &-lg {
                .card--blogPost__image {
                    flex: 0 0 540px;
                    height: 366px;
                }
            }
        }
    }
}
