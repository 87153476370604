.cm_videoBanner {
    transition: padding 0.2s linear;

    .video__container {
        > a {

            @include media-breakpoint-up(lg) {
                transition: transform 0.1s linear;
                &:hover {
                    transform: scale(1.03);
                }
            }

            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                top: 0;
                left: 0;
                right: 0;
                background-color: rgba(0,0,0,0.5);
                z-index: 1;
                display: flex;
            }

            img {
                @include Dropshadow($colour: rgba(0,0,0,0.60), $blur: 50px, $spread: 5px, $inset: false, $shiftRight: 0px, $shiftDown: 0px);
            }

            span {
                
                z-index: 2;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &:hover {
                > span {
                    background-color: lighten(map-get($colours, pink), 5%);
                }
            }
        }
    }
}
