$arrowSize: 10px;

.carousel {
    
    // cursor: grab;

    figure {
        margin:0;
        display: block;
    }
    
    img {
        max-width: 100%; 
        min-height: 100%;
    }

    .splide__arrow {opacity: 1; }

    // mods
    &--overflow {    
        .splide__track{
            overflow: visible;
        }
    }
    
    &--pipsA {
        .splide__pagination {
            position: static;
            margin-top: 30px;
            li {
                position: relative;
                width: 32px;
                height: 32px;
                background-color: map-get($map: $colours, $key: white); 
                text-align: center;
                border-radius: 50%;
                border: 1px solid map-get($map: $colours, $key: greyB);
                button {
                    transition: opacity 0.15s ease;
                    opacity: 0;
                    position:absolute;
                    margin: 0;
                    width: 15px;
                    height: 15px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    &.is-active {
                        opacity: 1;
                        background: map-get($map: $colours, $key: purple);
                    }
                }
                + li {
                    margin-left: 10px;
                }
            }
        }
    }

    &--pipsB {
        .splide__pagination{
            li + li {margin-left:8px;}
        }
        .splide__pagination__page {
            margin:0; 
            width: 7px; height: 7px; 
            opacity: 1;
            &.is-active{transform: scale(1.44);}
        }

    }
    
    &--arrowsA {

        display: flex;
        flex-direction: column;

        .splide__track{order:1} 
        
        .splide__arrows {
            margin-top: 30px;
            order:2;
            position:relative;
            display: flex;
            justify-content: center;
            button {
                left: unset;
                transform: none;
                position: static;
                width: 50px;
                height: 50px;
                background: map-get($map: $colours, $key: white);
                border:1px solid map-get($map: $colours, $key: greyB);
                
                &:disabled {
                    opacity: 0.2;
                }

                svg{display: none;}
                
                + button {
                    margin-left: 30px;
                }

                &::before {
                    content:'';
                    width: 0; 
                    height: 0; 
                    border-top: 9px solid transparent;
                    border-bottom: 9px solid transparent;
                    border-left: 14px solid map-get($map: $colours, $key: purple);
                    position:relative;
                }
                
                &.splide__arrow--prev::before{
                    transform: rotate(180deg);
                    left: -3px
                }
                &.splide__arrow--next::before{
                    
                    left: 2px
                }
            }
        }

        .splide__pagination{order:3}

    }

    &--arrowsB {
        .splide__arrow {
            width: 46px;
            height: 46px;
            background-color: rgba(255,255,255,1);
            background-repeat: no-repeat;
           
            &[disabled] {
                opacity: 0;
            }
            svg { display: none;}
            &--prev {
                background-position: 16px 50%;
                background-image: url('#{map-get($appPaths, "images")}/icons/left-arrow.svg');
            }
            &--next {
                background-position: 19px 50%;
                background-image: url('#{map-get($appPaths, "images")}/icons/right-arrow.svg');
            }
        }
    }

    &--arrowsC {
        .splide__arrow {
            width: 24px;
            height: 24px;
            background-color: rgba(255,255,255,1);
            background-repeat: no-repeat;

            &[disabled] {
                opacity: 0;
            }
            svg { display: none;}
            &--prev {
                background-size: 7px;
                background-position: 8px 50%;
                background-image: url('#{map-get($appPaths, "images")}/icons/left-arrow.svg');
            }
            &--next {
                background-size: 7px;
                background-position: 10px 50%;
                background-image: url('#{map-get($appPaths, "images")}/icons/right-arrow.svg');
            }
        }
    }

    &--similar {
        margin-top: 15px;
        margin-bottom: 60px;

        @include media-breakpoint-down(xs){
            .splide__pagination {display: none;}
        }
        @include media-breakpoint-up(sm){
            .splide__arrows {display: none;}
            
        }

    }

    &--autoscroll {
        @include media-breakpoint-up(md){
            &::before, &::after {
                content: '';
                width: 10%;
                position: absolute;
                z-index: 1;
                top: 0;
                bottom: 0;
            }
            &::before{
                left:0;
                background: rgb(255,255,255);
                background: linear-gradient(90deg, rgba(255,255,255,1) 30%, rgba(241,237,255,0) 100%);
            }
            &::after {
                right: 0;
                background: rgb(255,255,255);
                background: linear-gradient(270deg, rgba(255,255,255,1) 30%, rgba(241,237,255,0) 100%);
            }
        }
        
    }

    &--date-tiles {
        margin: 0 10px;

        .splide__arrow{
            background-color: transparent;

            &--prev, &--next{
                &::after{
                    position: absolute;
                    content: '';
                    width: 0; 
                    height: 0;
                    border-top: $arrowSize solid transparent;
                    border-bottom: $arrowSize solid transparent;
                    transition: all 0.15s linear;
                }
            }

            &--prev{
                left: -35px;
                &::after {
                    border-right: $arrowSize solid map-get($map: $colours, $key: purple);
                }
            }
            &--next{
                right: -35px;
                &::after {
                    border-left: $arrowSize solid map-get($map: $colours, $key: purple);
                }
            }

            svg {display: none;}

        }
    }


    //elems
    &__counter {
        background-image: url('#{map-get($appPaths, "images")}/camera-icon.svg');
        background-repeat: no-repeat;
        background-position: 15px 14px;
        background-color: white;
        padding: 10px 20px 10px 40px;
        border-radius: 40px;
        font-size: 1.3rem;
    }
}


