@import "../abstracts/_colours.scss";

@mixin tagBase {
    padding: 5px 6px 4px;
    background: Red;
    display: inline-block;
    line-height: 1.6rem; 
    font-size: 1rem;
    margin-right: 5px;
    letter-spacing: 0.04rem;
}

.tag {
    &--purple {
        @include tagBase();
        color: white;
        background-color: map-get($map: $colours, $key: purple);
    }
}

.managed-tag {
    position: absolute;
    color: white;
    margin: 0;
    z-index: 1;
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 6px 12px;
    background-color: #35ffd3;
    color: #02755a;
    left: 8px;
    top: 8px;

    @media only screen and (min-width:1200px) {
        top: 38px;
    }
}

/*BC CAROUSEL TAGS*/
.bc-tags-container {

	display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;

	position: absolute;

	&__tag {
		position: relative;
		color: white;
		margin: 0;
		z-index: 1;
		font-size: 1.3rem;
		line-height: 2rem;
		padding: 6px 12px;
		left: 8px;
		top: 8px;
	
		@media only screen and (min-width:1200px) {
			top: 20px;
			left: 20px;
		}

		&:not(:last-child) {
			margin-right: 1rem;
		}

		&--managed {
			background-color: #35ffd3;
			color: #034f3c;//#02755a;
		}

		&--special {
			background-color: #5f34c1;
			color: #ffffff;
			top: unset !important;
			font-weight: bold;
			width: fit-content;
			max-width: calc(100% - 20px);

			// @include MQ(380px) {
			// 	width: 94.5%;
			// }

			// @include MQ(420px) {
			// 	width: 95%;
			// }

			// @include MQ(500px) {
			// 	width: 96%;
			// }

			// @include MQ(765px) {
			// 	width: 94.5%;
			// }

			// @include MQ(991px) {
			// 	width: 96%;
			// }

			// @include MQ(1200px) {
			// 	width: 94%;
			// }

			// @include MQ(1300px) {
			// 	width: 94%;
			// }
		}
	}

}
