@import "../abstracts/_colours.scss";

/* Customize the label (the container) */
.form-check-custom {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 13px;
    user-select: none;

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark {
        background-color: $white;
    }

    /* When the checkbox is checked, add background */
    input:checked ~ .checkmark {
        background-color: map-get($map: $colours, $key: greyB);
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
        background-image: url('#{map-get($appPaths, "images")}/check.svg');
        width: 13px;
        height: 11px;
        top: 2px;
        left: 1px;
    }
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 1px;
    height: 18px;
    width: 18px;
    background-color: $white;
    border: 1px solid map-get($map: $colours, $key: greyB);
    cursor: pointer;

    &:after {
        content: "";
        position: absolute;
        display: none;
    } 
}
