@import "../abstracts/_colours.scss";

$bgcol: #faf9ff;

.cm_searchBanner {
    /* background-image: -webkit-gradient(linear, right top, left top, color-stop(2%, #DFDAFF), color-stop(54%, #F4F2FF), to(#ECE8FF));
    background-image: linear-gradient(270deg, #DFDAFF 2%, #F4F2FF 54%, #ECE8FF 100%);
    background-size: cover;
    background-position: center;*/
    background-color: $bgcol !important;

    > .container-fluid {
        max-width: 1300px;
    }

    .floating-images {
        font-size: 1.2rem;
    }

    header {
        h1 {
            font-size: 2.5rem;
            line-height: 4rem;
        }
    }

    .location_searcher a {
        text-decoration: none;
    }

    .staff_pill {
        border: 0 !important;
        margin-left: -15px;
        margin-right: -15px;
        background-color: $bgcol !important;

        .contact-list {
            img {
                left: 0;
                top: 0;

                &.email {
                    width: 30px;
                    top: -1px;
                }

                &.phone {
                    width: 20px;
                    left: 13px;
                    top: 3px;
                }
            }
        }

        img {
            max-width: 300px;

			@include MQ(450px) {
				max-width: unset;
			}
        }

        @include media-breakpoint-up(lg) {
            border-radius: 50px;
            border: 1px solid map-get($colours, purpleI);
            background-color: map-get($colours, purpleJ);

            img {
                width: 100%;
                max-width: unset;
            }

            > div {
                font-size: 1.5rem;

                &:first-of-type {
                    width: 260px;
                }
            }
        }
    }

    &__ancilliary {

        div + div {
            margin-top: 5px;
        }

        @include media-breakpoint-up(lg) {

            div + div {
                border-left: 1px solid map-get($map: $colours, $key: purpleI);
                padding-top: 6px;
                padding-bottom: 6px;
            }
        }
    }
}

.floating-images {
    
    .floating-image {        
        z-index: 2;
        background-size: cover;
        background-position: center center;

        > div {
            border: 5px solid map-get($map: $colours, $key: white);
            @extend .shadowA;
        }

        span.caption {
            bottom: -5px;
            padding-right: 8px;
        }

        &--1 {    
            max-width: 42.903225806451612903225806451613%;
            margin-left: calc(100% - 42.903225806451612903225806451613% - 6.4516129032258064516129032258065%);
            div {
                padding-top: 74.358974358974358974358974358974%;
            }
        }

        &--2 {
            max-width: 46.774193548387096774193548387097%;
            margin-top: -18%;
            div {
                padding-top: 73.921568627450980392156862745098%;
            }
        }

        &--3 {
            z-index: 1;
            max-width: 57.258064516129032258064516129032%;
            margin-top: -14%;
            margin-left: calc(100% - 57.258064516129032258064516129032%);
            div {
                padding-top: 63.719512195121951219512195121951%;
            }
        }

        &--4 {
            max-width: 49.193548387096774193548387096774%;
            margin-top: -20%;
            div {
                padding-top: 73.921200750469043151969981238274%;
            }
        }

        &--5 {
            max-width: 45.161290322580645161290322580645%;
            margin-left: calc(100% - 45.161290322580645161290322580645% - 2.7419354838709677419354838709677%);
            margin-top: -14%;
            div {
                padding-top: 73.469387755102040816326530612245%;
            }
        }

    }

    .cap-right {
        span.caption{
            right: 0;
            padding-right: 0;
            padding-left: 8px;
        }
    }

}
