.cm_leftRightSplitHero, .cm_leftRightSplitHeroFormModal{

    small {
        font-size: 1.1rem;
        line-height: 19px;

        @include media-breakpoint-up(md) {
            width: 90%;
        }
    }

    .btn {
        border-radius: 2px;
        width: auto;
        text-decoration: none;

        svg {
            height: 20px;
            top: 3px;
            position: relative;

            @include media-breakpoint-up(md) {
                height: 24px;
                top: 3px;
                position: absolute;
                right: 30px;
                top: calc(50% - 12px);
            }
        }

        @media only screen and (max-width: 400px) {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            width: auto;
        }
    }

    .hero-right {
        height: 175px;

        @include media-breakpoint-up(sm) {
            height: 300px;
        }

        @include media-breakpoint-up(md) {
            height: 555px;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                border-top: 555px solid #F4F6F9;
                border-right: 73px solid transparent;
                z-index: 1;
            }
        }
    }

    .hero-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @include media-breakpoint-up(lg) {
            background-position: right center;
        }
    }

    .btn-4 {
        font-size: 1.8rem;
        border: 1px solid;
        

        span {
            z-index: 20;
        }

        &::after {
            background: #fff;
            content: "";
            height: 155px;
            left: -75px;
            opacity: .2;
            position: absolute;
            top: -50px;
            -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
            -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            width: 50px;
            z-index: 0;
        }

        &:hover {
            &::after {
                left: 120%;
                -webkit-transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
                transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
            }
        }
    }

}