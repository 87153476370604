@mixin avatarBase() {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    display: block;
    margin-right: 15px;
    text-align: center;
    line-height: 50px;
    font-weight: bold;
}

.avatar{
    &--brown {
        @include avatarBase();
        background-color: map-get($map: $colours, $key: brownB);
        color: map-get($map: $colours, $key: brownC);
    }
    &--pink {
        @include avatarBase();
        background-color: map-get($map: $colours, $key: pinkC);
        color: map-get($map: $colours, $key: pinkB);
    }
}