@import "../abstracts/_colours.scss";

.report-banner {
	max-width: 1270px;
	margin: auto;

	figure {
		background-repeat: no-repeat;
		background-size: auto 500px;
		background-position: top center;
		background-attachment: fixed;
		min-height: 300px;
	}

	@include media-breakpoint-up(lg) {
		figure {
			height: 515px;
			background-size: auto 635px;
		}
	}
}

#reportSide {
	> header {
		margin-top: -100px;
		border-bottom: 1px solid map-get($map: $colours, $key: greyB);
	}

	nav {
		font-size: 1.3rem;
		> strong {
			font-family: "eina";
			font-size: 1.7rem;
		}
		ol {
			padding-left: 0;
			list-style-position: inside;
			margin: 10px 0 30px 0;
		}
	}

	// NOT SURE ABOUT THIS ONE, CROSS REF WITH BLOG
	a {
		margin-top: 30px;
	}
}

#reportMain {
	@include media-breakpoint-up(lg) {
		margin-top: -50px - 120px;
		border-left: 1px solid map-get($map: $colours, $key: greyB);
	}

	padding: 0 1rem;

	img {
		max-width: 100%;
		height: unset !important;
	}
}

.report-type {
	font-size: 1.2rem;
	background-image: linear-gradient(180deg, #5c489c 0%, #957ecb 100%);
	font-family: "maison_neue_bold";
	padding: 9px 20px 6px;

	@include media-breakpoint-up(lg) {
		font-size: 1.6rem;
		line-height: 2.7rem;
	}
}

.report-author {
	border-top: 1px solid map-get($map: $colours, $key: greyB);
	border-bottom: 1px solid map-get($map: $colours, $key: greyB);

	@include media-breakpoint-up(lg) {
		margin-left: -40px;
	}
}

.ThreeColTable {
	& table {
		width: 100%;
		max-width: 600px;
		margin: 1rem auto;

		padding: 0;

		color: #354150;

		& th {
			font-size: 1.6rem;
		}

		& td {
			font-size: 1.4rem;
		}

		@include MQ(800px) {
			max-width: 800px;
		}
		@media only screen and (min-device-width: 761px) and (max-device-width: 767px) {
			& tr {
				border: 1px solid #000000;

				& th {
					background: #f2eeff;
					color: #354150;
				}

				& th,
				td {
					padding: 0.25rem;

					&:nth-of-type(1) {
						width: 25%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(2) {
						width: 35%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(3) {
						width: 40%;
					}
				}

				&:nth-child(even) {
					background: #354150;
					color: #354150;
				}
			}
		}

		@include MQ(1024px) {
			& tr {
				border: 1px solid black;

				& th {
					background: #f2eeff;
					color: #354150;
				}

				& th,
				td {
					padding: 0.5rem;

					&:nth-of-type(1) {
						width: 25%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(2) {
						width: 35%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(3) {
						width: 40%;
					}
				}

				&:nth-child(even) {
					background: #f2eeff;
					color: #354150;
				}
			}
		}
	}
}
.FiveColTable {
	& table {
		width: 100%;
		max-width: 600px;
		margin: 1rem auto;

		padding: 0;

		color: #354150;

		& th {
			font-size: 1.6rem;
		}

		& td {
			font-size: 1.4rem;
		}

		@include MQ(800px) {
			max-width: 800px;
		}
		@media only screen and (min-device-width: 761px) and (max-device-width: 767px) {
			& tr {
				border: 1px solid #000000;

				& th {
					background: #f2eeff;
					color: #354150;
				}

				& th,
				td {
					padding: 0.25rem;

					&:nth-of-type(1) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(2) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(3) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(4) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(5) {
						width: 20%;
					}
				}

				&:nth-child(even) {
					background: #354150;
					color: #354150;
				}
			}
		}

		@include MQ(1024px) {
			& tr {
				border: 1px solid black;

				& th {
					background: #f2eeff;
					color: #354150;
				}

				& th,
				td {
					padding: 0.5rem;

					&:nth-of-type(1) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(2) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(3) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(4) {
						width: 20%;
						border-right: 1px solid #b1b1b1;
					}

					&:nth-of-type(5) {
						width: 20%;
					}
				}

				&:nth-child(even) {
					background: #f2eeff;
					color: #354150;
				}
			}
		}
	}
}

/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
	@media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      //margin: 0 0 1rem 0;
	  //border-top: 1px solid $theme_secondary;
    }
      
    tr:nth-child(odd) {
      background: #f2eeff;
	  color: #354150;
    }
    
		td {
			/* Behave  like a "row" */
			border-top: 1px solid black;
			border-left: 1px solid black;
			border-right: 1px solid black;
			//border-bottom: 1px solid #eee;
			position: relative;
			padding: 0.5rem 0.5rem 0.5rem 50%;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}

		td:after {
			content: "";
			display: block;
			width: 1px;
			height: 101%;
			background: #191970;
			right: 53%;
			top: 0;
			position: absolute;
		}

		tr:last-child { border-bottom: 1px solid #f2eeff; }

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		// td:nth-of-type(1):before { content: attr(data-content); }
		// td:nth-of-type(2):before { content: "Last Name"; }
		// td:nth-of-type(3):before { content: "Job Title"; }

		/*THE BELOW BIT OF MAGIC USES THE DATA POINTS ON THE TD TO CREATE TABLE HEADERS ON MOBILE*/

		[data-content]:before {
			content: attr(data-content);
			white-space: pre-wrap;
			top: 10px;
			font-weight: bold;
		}
	}

	.reportsCallToAction {
		margin: 2rem 0;
		&__container {
			& a {
				cursor: pointer;
				border-radius: 0.3rem;
				border: 0;
				display: inline-block;
				transition: all 0.15s ease;
				text-align: center;
				text-decoration: none;
				color: #fff;
				background-color: #f37ed5;
				line-height: 4.6rem;
				padding: 0 15px;
	
				&:hover {
					background-color: #f595dd;
				}
			}
		}
	}

	.reportsDefinition {
		background-color: #faf9ff;
		margin: 2rem 0;

		&__header {
			padding: 1rem;
			border: 1px solid #e5e5e5;
			font-size: 1.6rem;
			font-weight: bold;
			background-color: #ffffff;

			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			flex-wrap: nowrap;

			& img {
				width: 40px;
			}

			& h2 {
				width: calc(100% - 40px);
				padding-left: 5px;
				font-size: 3rem;
			}
		}

		&__content {
			padding: 1rem;
			font-size: 1.6rem;
		}
	}