.stars {
    display: inline-block;
    height: 23px;
    position: relative;
    top: 4px;
    text-align: left;
    margin: 0 2px;
    
    .star {
        display: inline-block;
        width: 23px;
        height: 23px;
        background: url('#{map-get($appPaths, "images")}/icons/star.svg');
        background-size: contain;
    
        + .star {
            margin-left: 1px;
        }
    }

}

.stars--square {
    display: inline-block;
    height: 21px; 

    .star {
        display: inline-block;
        width: 21px; height: 21px; 
        background: url('#{map-get($appPaths, "images")}/icons/sq-star.svg');
        background-size: contain;
        + .star{
            margin-left: 2px;
        }
    }
}