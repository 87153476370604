@mixin pillBase() {
    display: inline-block;
    line-height: 3.3rem;
    padding: 0 20px;
    border-radius: 100px;
    font-size: 1.4rem;
}

.pill {

	display: inline-block;
    line-height: 3.3rem;
    padding: 0 20px;
    border-radius: 100px;
    font-size: 1.4rem;

    &--podcast {
        @include pillBase();
        color: map-get($map: $colours, $key: grey);
        background-color:map-get($map: $colours, $key: cyanB);
    }
    &--interview {
        @include pillBase();
        color: map-get($map: $colours, $key: grey);
        background-color: map-get($map: $colours, $key: yellowA);
    }
    &--occupancy{
        @include pillBase();
        line-height: 2.5rem;
        background-color: map-get($map: $colours, $key: purpleC);
        color: map-get($map: $colours, $key: purple);
    }
}