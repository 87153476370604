@import "../abstracts/_colours.scss";

.cm_imageBanner {
    
    figure {
        padding-top: 56.25%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        z-index: 1;
    }

    @include media-breakpoint-up(md) {
        .container {
            z-index: 2;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
        }
        .banner_content {
            border-left: 15px solid map-get($map: $colours, $key: purple);
        }
    }

   
    @include media-breakpoint-up(lg) {
        figure {
            height: 515px;
        }

        .banner_content {
            max-width: 575px;
        }
    }
}
