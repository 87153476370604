@import "../abstracts/_colours.scss";


.cm_usp {
    $spacer: 20px;
    $tickDim: 20px;

    line-height: 2.4rem;
    font-size: 1.5rem;

    

    .usp-item {
        line-height: 2rem;

        span {

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: $tickDim;
                height: $tickDim;
                background-image: url('#{map-get($appPaths, "images")}/icons/tick.svg');
                background-size: contain;
            }
        }
    }

    .row {
        .col-12 {
            + .col-12 {
                margin-top: $spacer;
            }

            @include media-breakpoint-up(md) {
                + .col-12 {
                    margin-top: 0;
                }

                &:nth-child(n+3) {
                    margin-top: $spacer;
                }
            }

            @include media-breakpoint-up(lg) {
                &:nth-child(n+3) {
                    margin-top: 0;
                }
                &:nth-child(n+5) {
                    margin-top: $spacer;
                }
            }
        }
    }
}