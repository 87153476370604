@import "../abstracts/_colours.scss";

html {
	font-size: 10px;
	letter-spacing: 0.1px;
}

.f_ {
	&eina {
		font-family: $header-font-family !important;
		font-weight: 400;
	}

	&maison_neue_bold {
		font-family: $body-font-family !important;
		font-weight: 600;
	}

	&maison_neue_book {
		font-family: $body-font-family !important;
		font-weight: 600;
	}

	&maison_neue_demi {
		font-family: $body-font-family !important;
		font-weight: 400;
	}

	&maison_neue_light {
		font-family: $body-font-family !important;
		font-weight: 400;
	}

	&maison_neue_light_italic {
		font-family: $body-font-family !important;
		font-weight: 300;
		font-style: italic;
	}
}

html,
.dropdown__menu {
	font-family: $body-font-family, "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
}

body {
	font-size: 1.6rem;
	line-height: 2.9rem;
}

/* -------------------------------------------------------------
 * Headers
 */

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
	font-family: $header-font-family;
	font-weight: 700 !important;
	margin: 0;
	color: map-get($map: $colours, $key: blue);
	letter-spacing: normal;
}

h1,
.h1 {
	font-size: 2.9rem;
	line-height: 3.4rem;
}

h2,
.h2 {
	font-size: 2.4rem;
	line-height: 3rem;
}

h3,
.h3 {
	font-size: 2rem;
	line-height: 2.6rem;
}

h4,
.h4 {
}

h5,
.h5 {
}

h6,
.h6 {
	font-size: 1.5rem;
}

/* -------------------------------------------------------------
 * Typographic Styles
 */

.underline {
	text-decoration: underline !important;
}

strong,
.strong {
	@extend .f_maison_neue_bold;
}

small,
.txt-small {
	font-size: 1.4rem;
	line-height: 2.4rem;
}

.intro {
	font-size: 2.1rem;
	line-height: 3.2rem;
}

ol,
ul {
	line-height: 2.4rem;
}

.leader_2 {
	font-size: 1.4rem;
	line-height: 2.3rem;
	font-weight: normal;
	font-family: $body-font-family;
}

.cite {
	background-color: #f0edfa;
	border: 1px solid #d8cef7;
	color: #5c489c;
	font-family: $body-font-family !important;
	font-weight: 600;
	position: relative;
	font-size: 1.8rem;
	line-height: 2.7rem;
	margin-top: 68px;
	margin-bottom: 50px;
	padding: 47px 32px 34px 26px;
	z-index: 2;

	&::before,
	&::after {
		position: absolute;
		content: "";
		width: 63px;
		height: 42px;
		background: url('#{map-get($appPaths, "images")}/case_study/cite.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}

	&::before {
		left: -10px;
		top: -10px;
	}

	&:after {
		right: -10px;
		bottom: -10px;
		transform: rotate(180deg);
	}

	@include media-breakpoint-up(lg) {
		margin-left: -41px;
		padding-left: 40px;
	}
}
/* -------------------------------------------------------------
   -------------------------------------------------------------
 * Media
 */

@include media_breakpoint_up(md) {
	h2,
	.h2 {
		font-size: 3.2rem;
		line-height: 4.4rem;
	}
}

@include media_breakpoint_up(lg) {
	h1,
	.h1 {
		font-size: 4.4rem;
		line-height: 5rem;
	}
	h2,
	.h2 {
		font-size: 3.8rem;
		line-height: 4.4rem;
	}
	h3,
	.h3 {
		font-size: 2.4rem;
		line-height: 3rem;
	}
	.txt-intro {
		font-size: 2.1rem;
		line-height: 3.2rem;
	}

	.leader {
		font-size: 2.1rem;
		line-height: 3.2rem;
	}

	.leader_2 {
		font-size: 1.7rem;
		line-height: 2.3rem;
	}
}
