.cm_clientCards {

    .card-row {
        > div {
            &:nth-child(n+2) {
                margin-top: 15px;
            }

            @include media-breakpoint-up(sm) {
                &:nth-child(n+2) {
                    margin-top: 30px;
                }
            }

            @include media-breakpoint-up(md) {
                &:nth-child(n+2) {
                    margin-top: 0;
                }

                &:nth-child(n+3) {
                    margin-top: 30px;
                }
            }

            @include media-breakpoint-up(lg) {
                &:nth-child(n+3) {
                    margin-top: 0;
                }

                &:nth-child(n+4) {
                    margin-top: 30px;
                }
            }
        }
    }

}
