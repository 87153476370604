.all-locations {

	& header {
		padding: 1rem 0;
	}

	& ul {
		margin: 0;
		padding: 0 2rem;
		list-style-type: none;
		
		& li {
			padding: 1rem 0;
		}

	}

}