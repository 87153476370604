@mixin resultCtnrBase {
    $br: 6px;
    @include Dropshadow(rgba(214, 208, 247, 0.5), 12px, $shiftDown: 13px);
    position: absolute;
    top: 100%;
    text-align: left;
    z-index: 10;
    border-radius: 0 0 $br $br;
    
    > .no-results{
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    ul {
        @extend .list-unstyled;
        margin:0;    
        white-space: pre;
    }
    
    li{
        &.active{
            a{
                background-color: map-get($colours, greyH);  
            }
            
        }
        + li { margin:0; }
    }
    a {
        display: block;
        padding-left: 30px;
        padding-right: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 65px;
        @include media-breakpoint-up(lg) {
            line-height: 45px;
        }
        &:hover {
            background-color: map-get($colours, greyH);
        }
    }
}

.location_searcher {
    position: relative;

    button {
        cursor: pointer;
    }

    .no-results {
        line-height: 45px;
        padding: 0 30px;
        color: map-get($colours, purple);
        font-weight: 600;
    }

    &--a {
        margin-left: auto;
        margin-right: auto;

        &.searching {
            button {
                background-image: url('#{map-get($appPaths, "images")}/icons/spinA.svg');
            }
        }

        form {

            input[type="search"] {

                @extend .shadowA;
                border-radius: 25px;
                padding: 0 30px 0 80px;
                line-height: 5.2rem;
                border: 1px solid map-get($colours, purpleM);

                @include media-breakpoint-up(lg) {
                    line-height: 7.4rem;
                    font-size: 2rem;
                    border-radius: 38px;
                    padding-left: 110px;
                }
            }

            button {
                width: 60px;
                border: 0;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                z-index: 1;
                color: map-get($map: $colours, $key: white);
                border-radius: 100px 0 0 100px;
                background-color: map-get($map: $colours, $key: purple);//map-get($map: $colours, $key: pink);
                background-image: url('#{map-get($appPaths, "images")}/icons/search.svg');
                background-repeat: no-repeat;
                background-size: 23px 23px;
                background-position: 20px center;

                @include media-breakpoint-up(lg) {
                    width: 86px;
                    background-size: 32px 32px;
                    background-position: 30px center;
                }
            }
        }

        &.open {
            form input[type="search"] {
                border-bottom-right-radius: 0;
            }

            .results {
                display: block;
            }
        }

        .results {
            @include resultCtnrBase();
            border: 1px solid #D3CEF0;
            border-top: 0;
            background-color: white;
            width: calc(100% - 59px);
            display: none;
            left: 59px;

            a {
                padding-left: 20px;
                padding-right: 20px;
            }

            @include media-breakpoint-up(lg) {
                width: calc(100% - 85px);
                left: 85px;

                a {
                    padding-left: 25px;
                    padding-right: 25px;
                }
            }
        }
    }

    &--b {


        form {
            position: relative;
        }

        &.searching {
            button {
                background-image: url('#{map-get($appPaths, "images")}/icons/spinB.svg');
                background-size: contain;
            }
        }

        button {
            padding: 1.5rem 1.5rem;
            position: absolute;
            top: 50%;
            right: 0;
			border: 1px solid #5f34c1;//#f37ed5;
			border-radius: 25px;
            transform: translate(-50%, -50%);
            background-repeat: no-repeat;
            background-color: #5f34c1;//;
            width: 18px;
            height: 18px;
            background-image: url('#{map-get($appPaths, "images")}/Icon-MagnifyingGlass.svg');
			background-position-x: center;
			background-position-y: center;
        }

        input[type="search"] {
            //border: 1px solid map-get($map: $colours, $key: greyB);
            // border: 1px solid #b7b5bf;
			box-shadow: 0 0 6px rgba(33, 33, 33, 0.25);
            background-color: #ffffff;//map-get($colours, greyC);
            padding: 0 0 0 25px;
            line-height: 36px;
            border-radius: 18px;

            &::placeholder {
                color: lighten(#4C545D, 50%) !important;
                font-weight: 400;
            }

            @include media-breakpoint-up(md) {
                line-height: 46px;
                border-radius: 23px;
            }
        }

        .results {
            @include resultCtnrBase();
            // border: 1px solid #b7b5bf;
			box-shadow: 0 0 6px rgba(33, 33, 33, 0.1) !important;
            background-color: #ffffff;//#fafafa;
            border-top: 0;
            box-shadow: none;
            width: 100%;
        }

        &.open {
            input[type="search"] {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
        // @include media-breakpoint-up(sm) {
        //     width: 350px;
        // }
        // @include media-breakpoint-up(md) {
        //     width: 440px;
        // }
        @include media-breakpoint-up(lg) {
            input[type="search"] {
                height: 46px;
                line-height: 46px;
                border-radius: 23px;
            }
            // width: 280px;
        }

        @media only screen and (min-width: 1380px) {
            // width: 400px;
        }
    }

    // 404 / error
    &--c {
        @extend .location_searcher--a;
        max-width: 630px;
        margin: 0 auto 0 auto;

        form {
            button {
                background-color: map-get($map: $colours, $key: purple);
            }
        }
    }
}