.pagination {
    ul{
        @extend .list-unstyled;
        margin: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        border:1px solid #E2E5E8;
        background-color: rgba(226,229,232,0.5);
        color: #042040;
        text-decoration: none;
        font-size: 1.2rem;
        font-family: $body-font-family;
		font-weight: 600;
    }
    &__item + &__item {
        margin: 0 0 0 5px;
    }

    &__item {line-height: 36px;
        &--active {
            a {
                color: map-get($map: $colours, $key: white);
                background-color: map-get($map: $colours, $key: purple);
                border-color: map-get($map: $colours, $key: purple);
            }
        }
    }

    @include media-breakpoint-up(lg){
        a {
            width: 49px; 
            height: 49px;
            font-size: 1.6rem;
        }

        &__item + &__item {
            margin: 0 0 0 10px;
        }
    }

}