.modal-window {
    z-index: 1999999999;

    form {
        margin: 0 auto;

        input[type="text"],
        input[type="email"],
        input[type="tel"],
        select,
        textarea {
            background-color: #F9F9FB;
            border: 1px solid #E5E4E9;
        }

        input[type="submit"] {
            @extend .btn--pink;
        }

        label.error {
            margin-top: 5px;
            margin-bottom: 10px;
        }
    }

    &.consultationForm {
        form {
            max-width: 425px;
        }

        .tingle-modal-box {
            max-width: 700px;
        }

        .tingle-modal-box__content {
            padding-top: 45px;
            padding-bottom: 45px;
        }

        header {
            margin-bottom: 30px;

            h2 {
                margin-bottom: 10px
            }

            p {
                margin: 0;
            }
        }
    }

    &.bcQuickEnquiry {
        .tingle-modal-box {
            max-width: 420px;
        }
    }

    &.vidsPodcasts {

        iframe {
            width: 300px;
            height: unquote($string: '#{1000*56.25/100}px');
            transition: all 0.15s ease;

            @include media-breakpoint-up(sm) {
                width: 500px;
            }

            @include media-breakpoint-up(md) {
                width: 700px;
            }

            @include media-breakpoint-up(lg) {
                width: 900px;
            }

            @include media-breakpoint-up(lg) {
                width: 1024px;
            }
        }

        .tingle-modal-box {
            background: transparent;
            max-width: unset;
            width: unset;
        }

        .tingle-modal-box__content {
            padding: 0;
        }
    }

    &.galleryModal {
        .tingle-modal-box {
            background: transparent;

            @include media-breakpoint-up(sm) {
                width: 500px;
            }

            @include media-breakpoint-up(md) {
                width: 700px;
            }

            @include media-breakpoint-up(lg) {
                width: 900px;
            }

            @include media-breakpoint-up(lg) {
                width: 1024px;
            }
        }

        .tingle-modal-box__content {
            padding: 0;
        }

        figure {
            position: relative;
            display: block;
            margin: 0;
            padding-top: 68%;
            overflow: hidden;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            min-width: 100%;
        }
    }

    &.bcEnqForm {

        @media only screen and (max-width: 540px) {
            .tingle-modal-box {
                height: calc(100vh - 60px);
            }
        }

        .tingle-modal-box {
            @media only screen and (min-width: 541px) and (max-width: 575px) {
                width: 80%;
            }

            @include media-breakpoint-up(sm) {
                width: 500px;
            }

            @include media-breakpoint-up(md) {
                width: 600px;
            }
        }
    }
}

.tingle-modal-box {
    overflow-y: scroll;
    overflow-x: hidden;
}