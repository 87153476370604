@mixin accordionBase{

}

.accordion {

    // business service
    &--locations, &--themeA {
        @include accordionBase();
        border-top: 1px solid #e3e3e3;
        .accordion {
            &__item {}
            &__header {
                
                border-bottom: 1px solid #e3e3e3;
            }
            &__toggle {
                cursor: pointer;
                &::before {
                    content: "";
                    position: absolute;
                    height: 32px;
                    width: 32px;
                    background-repeat: no-repeat;
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxjaXJjbGUgZmlsbD0iI0ZGNjVDMSIgY3g9IjE2IiBjeT0iMTYiIHI9IjE2Ii8+CiAgICA8cGF0aCBkPSJNMTAuMTQxIDE0Ljg1OUExLjE3IDEuMTcgMCAwMDkgMTZhMS4xNyAxLjE3IDAgMDAxLjE0MSAxLjE0MUgyMS44NkExLjE3IDEuMTcgMCAwMDIzIDE2YTEuMTcgMS4xNyAwIDAwLTEuMTQxLTEuMTQxSDEwLjE0eiIgZmlsbD0iI0ZGRiIgZmlsbC1ydWxlPSJub256ZXJvIi8+CiAgPC9nPgo8L3N2Zz4K");        
                    left: 0;
                    top: 50%;
                    transform: translate(0,-50%);
                    
                }

                &.collapsed {
                    &::before {
                        background-image: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMzIgMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxjaXJjbGUgZmlsbD0iI0Q5REVFNSIgY3g9IjE2IiBjeT0iMTYiIHI9IjE2Ii8+CiAgICA8cGF0aCBkPSJNMjEuODU5IDE0Ljg1OUgxNy4xNFYxMC4xNEExLjE3IDEuMTcgMCAwMDE2IDlhMS4xNyAxLjE3IDAgMDAtMS4xNDEgMS4xNDF2NC43MThIMTAuMTRBMS4xNyAxLjE3IDAgMDA5IDE2YTEuMTcgMS4xNyAwIDAwMS4xNDEgMS4xNDFoNC43MTh2NC43MThBMS4xNyAxLjE3IDAgMDAxNiAyM2ExLjE3IDEuMTcgMCAwMDEuMTQxLTEuMTQxVjE3LjE0aDQuNzE4QTEuMTcgMS4xNyAwIDAwMjMgMTZhMS4xNyAxLjE3IDAgMDAtMS4xNDEtMS4xNDF6IiBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz4KICA8L2c+Cjwvc3ZnPgo=");        
                    }
                }
                border: 0;
                color: map-get($map: $colours, $key: purple);
                background: none;
                text-align: left;
                width: 100%;
                font-size: 1.9rem;
                line-height: 5.3rem;
                position: relative;
                padding-left: 55px;

                @include media-breakpoint-up(lg){
                    font-size: 2.3rem;
                }

            }
            &__body {
                border-bottom: 1px solid #e3e3e3;
                padding: 20px 0;

                @include media-breakpoint-up(md){
                    padding-left: 55px;
                    padding-bottom: 40px;
                }
            }
        } 
    }

    //area guides
    &--themeB {
        @include accordionBase();
       
        .accordion {
            &__header {
                border-top: 1px solid #e3e3e3;
                border-bottom: 1px solid #e3e3e3;
            }
            &__toggle {
                cursor: pointer;
                border: 0;
                color: map-get($map: $colours, $key: blue);
                background: none;
                text-align: left;
                width: 100%;
                font-size: 1.5rem;
                line-height: 4.3rem;
                position: relative;
                font-family: $body-font-family;
                font-weight: 600;
                padding: 0;
                &::after {
                    content: '';
                    width: 14px;
                    height: 8px;
                    background: url('#{map-get($appPaths, "images")}/icons/up-arrow.svg');
                    background-size: contain;
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translate(0, -50%);
                }

                &.collapsed {
                    &::after{
                        background: url('#{map-get($appPaths, "images")}/icons/down-arrow.svg');
                        background-size: contain;
                    }
                }
            }
            &__body {
                h2{
                    font-size: 1.5rem;
                }
               font-size: 1.4rem;
               line-height: 2.4rem;
                padding: 10px 0;

                @include media-breakpoint-up(md){
                    padding-bottom: 40px;
                }
            }
        } 

      
    }

    
    &--locations{
        ul{
            @extend .list-unstyled;
            li {
                + li {
                    margin-top: 5px;
                }
            } 
        }
        a{color: map-get($map: $colours, $key: purple);
        &:hover{
            text-decoration: underline;
        }}
        .accordion__body{
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &--locations &--locations{
        border-top: 0;
        .accordion__item{
            &:first-of-type{
                
            }
        }
    }




    // mobile nav
    // 1
    &--mobileNav{
        @extend .accordion--themeB;
        
        .accordion {
            &__header {
                border-top: 0;
            }

            &__toggle {
                line-height: 70px;
                font-size: 1.5rem;
                
				font-family: $body-font-family;
				font-weight: 500;
                // font-weight: normal;
                padding: 0 15px;

                &.collapsed {
                    &::after {
                        background-image: url('#{map-get($appPaths, "images")}/icons/down-arrow-grey.svg');
                    }
                }
                &::after {
                    background-image: url('#{map-get($appPaths, "images")}/icons/up-arrow-grey.svg');
                    background-size: cover !important;
                    right: 15px;
                }
            }
            &__body {
                padding: 0;
                > ul {
                    border-bottom: 1px solid map-get($map: $colours, $key: greyB);
                    @extend .list-unstyled;
                    margin: 0;
                    
                    background-color: map-get($map: $colours, $key: purpleO);
                    padding-top: 15px; 
                    padding-bottom: 15px;

                    li { padding:0; margin: 0;}
                    a { border: 0; color: map-get($colours, purple);  padding-left: 30px; line-height: 4rem;}
                }
//2
                .accordion--mobileNav {
                    button {
                        padding-left: 30px;
                    }
                    .accordion__body{
//3
                        > ul{
                            a{ padding-left: 45px;}
                        }
                    }
                }
            }
        }
        

    }
}

// .dropdown{

//     &::after {
//         z-index: 1;
//         position: absolute;
//         content: '';
//         width: 80px;
//         height: 71px;
//         right: 0;
//         top: 0;
//         cursor: pointer;
//         background-image: url('#{map-get($appPaths, "images")}/icons/down-arrow-grey.svg');
//         background-repeat: no-repeat;
//         background-position: 42px center;
//     }

//     &:hover {
//         &::after {
//             background-image: url('#{map-get($appPaths, "images")}/icons/up-arrow-grey.svg');
//         }
//         > ul{
//             display: block;
//         }
//     }
//     ul{display: none}
// }
// > nav {
//     ul {
//         padding: 0;
//         margin: 0; 
//         list-style: none;
    
//         ul {
//             .purp {
//                 a{
//                     padding-left: 45px;
//                 }
//             }
//             li {
//                 > a, > span{
//                     padding-left: 30px;
//                 }
//             }
//         }

//         li {
//             margin: 0;
//             > a, > span {
//                 border-bottom: 1px solid map-get($map: $colours, $key: greyB);
//                 display: block;
//                 line-height: 70px;
//                 padding: 0 15px;
//             }
//         }
//     }

// }

// .purp {
//     border-bottom: 1px solid map-get($map: $colours, $key: greyB);
//     background-color: map-get($map: $colours, $key: purpleO);
//     padding-top: 15px; 
//     padding-bottom: 15px;
//     li{ padding:0; margin: 0;}
//     a { border: 0; padding: 0 0 0 30px; color: map-get($colours, purple); line-height: 4rem;}
// }